import { inject, Injectable } from '@angular/core';
import { DevicesStore } from '@feature/devices/devices.store';
import { GroupBase } from '@feature/policies/policies.types';
import { NotificationService } from '@ui/notification/notification.service';
import { tap } from 'rxjs';

import { DeviceAndroidUpdate, DeviceDetailsService } from '../device-details.service';
import { AndroidDevice } from '../device-details.types';

@Injectable({
	providedIn: 'root',
})
export class StatsService {
	#deviceDetailsService = inject(DeviceDetailsService);
	#devicesStore = inject(DevicesStore);
	#notificationService = inject(NotificationService);

	updateDevice(
		deviceId: string,
		data: DeviceAndroidUpdate,
		groups: GroupBase[],
		fieldName: string,
	) {
		this.#notificationService.open({
			message: `<strong>${fieldName}</strong> is updating.`,
		});

		return this.#deviceDetailsService.updateDevice(deviceId, data).pipe(
			tap(() => {
				this.#notificationService.openSuccess({
					message: `<strong>${fieldName}</strong> was updated.`,
				});
				this.updateDeviceStore(deviceId, {
					name: data.name,
					group: groups?.find((group) => group.id === data.group),
					uninstallKey: data.uninstallKey,
					releaseChannel: data.releaseChannel,
					workOrder: data.workOrder,
				});
			}),
		);
	}

	updateDeviceStore(deviceId: string, data: Partial<AndroidDevice>) {
		this.#devicesStore.updateDeviceDetail(deviceId, data);
	}
}
