import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	effect,
	inject,
	OnDestroy,
	signal,
	ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { CustomerBase } from '@feature/customer/customer.types';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { Subscription } from 'rxjs';

import { DevicesStore } from '../devices/devices.store';
import { PoliciesStore } from '../policies/policies.store';
import { UserStore } from '../user/user.store';
import { CUSTOMERS_PER_REQUEST } from './customers.constants';
import { CustomersService } from './customers.service';
import { CustomersStore } from './customers.store';

@Component({
	selector: 'csd-app-customers',
	standalone: true,
	templateUrl: './customers.component.html',
	styleUrl: './customers.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		LoaderComponent,
		MatIconModule,
		MatPaginatorModule,
		MatTableModule,
		LoadingIconComponent,
		EmptyStateComponent,
	],
})
export class CustomersComponent implements AfterViewInit, OnDestroy {
	@ViewChild(MatPaginator) paginator!: MatPaginator;

	readonly #customersStore = inject(CustomersStore);
	readonly #devicesStore = inject(DevicesStore);
	readonly #policiesStore = inject(PoliciesStore);
	readonly #userStore = inject(UserStore);
	#customersService = inject(CustomersService);
	#ref = inject(ChangeDetectorRef);
	#router = inject(Router);

	dataSource = signal<MatTableDataSource<CustomerBase>>(new MatTableDataSource());
	displayedColumns = ['code', 'name', 'email', 'phone'];
	isLoadingCustomers = this.#customersService.isLoading;
	totalCustomers = this.#customersStore.totalCustomers;
	subscriptions: Subscription[] = [];

	isFirstTimeLoading = computed(
		() => this.#customersService.isLoading() && this.#customersStore.totalCustomers() === 0,
	);

	constructor() {
		effect(
			() => {
				if (this.#userStore.selectedGroupId()) {
					this.#customersStore.setTotalCustomers(0);
					this.#customersStore.setLoadedCustomers(0);
					this.#customersStore.removeAllCustomers();

					const matTableData = new MatTableDataSource<CustomerBase>([]);
					matTableData.paginator = this.paginator;
					this.dataSource.set(matTableData);
					this.requestCustomers();
				}
			},
			{
				allowSignalWrites: true,
			},
		);
	}

	ngAfterViewInit(): void {
		this.paginator.page.subscribe(this.onPageChange.bind(this));
		const hasCustomers = this.#customersStore.customersList().length;

		if (hasCustomers) {
			this.updateDataSource();
			return;
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	onPageChange(pageEvent: PageEvent) {
		const currentPage = pageEvent.pageIndex;
		const downloadedCustomers = this.#customersStore.customersList().length;
		const itemsDisplayed = pageEvent.pageSize * currentPage;
		const itemsGapToLoadNews = pageEvent.pageSize * 2;
		const shouldLoadData = itemsDisplayed >= downloadedCustomers - itemsGapToLoadNews;

		if (shouldLoadData) {
			const zeroIndexFix = 1;
			this.requestCustomers(Math.round(downloadedCustomers / CUSTOMERS_PER_REQUEST) + zeroIndexFix);
		}
	}

	requestCustomers(page = 1) {
		this.#customersService
			.getAllCustomers(this.#userStore.selectedGroupId(), page)
			.subscribe(this.updateDataSource.bind(this));
	}

	setCustomer(customer: CustomerBase) {
		this.#policiesStore.reset();
		this.#devicesStore.reset();
		this.#router.navigate(['customer', customer.customerId, 'overview']);
		this.#customersStore.updateSelectedCustomer(customer.customerId);
	}

	updateDataSource() {
		const fixedData = [...(this.#customersStore.getCustomersList() || [])];
		fixedData.length = this.#customersStore.totalCustomers() || 0;

		if (fixedData.length === 1) {
			this.setCustomer(fixedData[0]);
			return;
		}

		const matTableData = new MatTableDataSource<CustomerBase>(fixedData);
		matTableData.paginator = this.paginator;
		this.dataSource.set(matTableData);
		this.#ref.detectChanges();
	}
}
