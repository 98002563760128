import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ButtonComponent } from '@ui/button/button.component';
import moment, { Moment } from 'moment';

@Component({
	selector: 'csd-app-history',
	standalone: true,
	templateUrl: './history.component.html',
	styleUrl: './history.component.scss',
	imports: [ButtonComponent, MatIconModule, MatSidenavModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent implements OnInit, OnDestroy {
	currentDate = signal<Moment>(moment());
	currentTime = signal<string>('');
	intervalId?: NodeJS.Timeout = undefined;

	ngOnInit(): void {
		const currentDateString = this.currentDate().format();
		this.setTimeFromServer({ datetime: currentDateString });
	}

	ngOnDestroy(): void {
		clearInterval(this.intervalId);
	}

	setTimeFromServer(response: Object) {
		const { datetime } = (response as Record<string, string>) || {};
		const time = formatDate(datetime, 'h:mm a', 'en-US');

		this.currentDate.set(moment(datetime));
		this.currentTime.set(time);
		this.updateTime();
	}

	updateTime() {
		const minuteInMili = 1000 * 60;

		this.intervalId = setInterval(() => {
			this.currentDate.set(this.currentDate().add(1, 'm'));
			this.currentTime.set(this.currentDate().format('h:mm a'));
		}, minuteInMili);
	}
}
