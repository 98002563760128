import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { finalize } from 'rxjs';

import { UserStore } from '../../user/user.store';

export interface DeviceAppsUpdate {
	appId: string;
	packageName: string;
	status: number;
	internetConfig: number;
}

export interface AppUpdateResponse {
	appId: string;
	status: number;
	appVersion: string;
	internetConfig: number;
	differentThanGroup: boolean;
	installed: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class AppsService {
	readonly #userStore = inject(UserStore);
	private apiService = inject(ApiService);

	isLoading = signal<boolean>(false);

	updateStatus(deviceId: string, data: DeviceAppsUpdate) {
		this.isLoading.set(true);

		return this.apiService
			.put<AppUpdateResponse>(`/android/device/appstatus/${deviceId}`, data, {
				params: { access: this.#userStore.selectedGroupId() },
			})
			.pipe(finalize(() => this.isLoading.set(false)));
	}

	removeAppValues(deviceId: string, appId: string) {
		return this.apiService.delete<AppUpdateResponse>(`/android/device/appvalues/${deviceId}`, {
			params: { appId },
		});
	}

	updateVpnMode(deviceId: string, allow: boolean) {
		this.isLoading.set(true);

		return this.apiService
			.put<void>(`/android/device/updatevpnmode/${deviceId}`, { params: { allow } })
			.pipe(finalize(() => this.isLoading.set(false)));
	}
}
