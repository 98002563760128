import { BreakpointObserver } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { NgClass } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationStart, Router, RouterLink, RouterOutlet } from '@angular/router';
import { CustomerSelectionComponent } from '@feature/customer-selection/customer-selection.component';
import { CustomersStore } from '@feature/customers/customers.store';
import { GroupsStore } from '@feature/groups/groups.store';
import { HistoryComponent } from '@feature/history/history.component';
import { UserService } from '@feature/user/user.service';
import { triggerFadeInOut } from '@root/app/animations';
import { filterNavigationTransition } from '@root/app/utils/filter-navigation-transition';
import { BreadcrumbComponent } from '@ui/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '@ui/button/button.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { filter, map } from 'rxjs';

import { MainLayoutService } from './main-layout.service';
import { SidebarComponent } from './sidebar/sidebar.component';

@Component({
	selector: 'csd-app-main-layout',
	standalone: true,
	templateUrl: './main-layout.component.html',
	styleUrl: './main-layout.component.scss',
	imports: [
		BreadcrumbComponent,
		ButtonComponent,
		CustomerSelectionComponent,
		EmptyStateComponent,
		HistoryComponent,
		LoaderComponent,
		MatIconModule,
		MatSidenavModule,
		NgClass,
		PortalModule,
		RouterLink,
		RouterOutlet,
		SidebarComponent,
	],
	animations: [triggerFadeInOut],
})
export class MainLayoutComponent {
	readonly #customersStore = inject(CustomersStore);
	readonly #groupsStore = inject(GroupsStore);
	#breakpointObserver = inject(BreakpointObserver);
	#mainLayoutService = inject(MainLayoutService);
	#userService = inject(UserService);
	#router = inject(Router);

	customer = this.#customersStore.selectedCustomer;
	isLoadingUserGroups = this.#userService.isLoadingGroups;
	isMobile = signal<boolean>(false);
	shouldDisplayConnect = this.#groupsStore.shouldDisplayConnect;
	shouldDisplayDashboard = this.#userService.shouldDisplayDashboard;
	shouldOpenSidenav = this.#mainLayoutService.isSidenavOpen;
	shouldOpenDrawer = this.#mainLayoutService.isDrawerOpen;

	drawerData = computed(() => ({
		title: this.#mainLayoutService.drawerTitle(),
		component: this.#mainLayoutService.drawerComponent(),
	}));
	userHasGroups = computed(() => !!this.#groupsStore.groupsList().length);

	isLoadingRoute = toSignal(
		this.#router.events.pipe(
			filter(filterNavigationTransition),
			map((e) => {
				const url = (e as NavigationStart)?.url || '';

				if (url.includes('customer')) {
					const urlSections = url.replace(/^\/|$\//gi, '').split('/');
					const detailsSection = urlSections.length >= 5;
					return !detailsSection && e instanceof NavigationStart;
				}

				return false;
			}),
		),
	);

	constructor() {
		this.startBreakpointObserver();
	}

	startBreakpointObserver() {
		this.#breakpointObserver.observe(['(max-width: 1024px)']).subscribe((screenSize) => {
			this.toggleSidenav(!screenSize.matches);
			this.isMobile.set(screenSize.matches);
		});
	}

	toggleSidenav(status: boolean) {
		this.#mainLayoutService.toggleSidenav(status);
	}

	toggleDrawer(status: boolean) {
		if (!status) {
			this.#mainLayoutService.closeDrawer();
		}
	}
}
