import { Routes } from '@angular/router';
import { UsersLinkComponent } from '@feature/users-link/users-link.component';
import { usersLinkResolver } from '@feature/users-link/users-link.resolver';
import { BASE_TITLE } from '@root/app/app.constants';

import { UsersComponent } from './users.component';

export default <Routes>[
	{
		path: '',
		title: `Users ${BASE_TITLE}`,
		data: { breadcrumb: '' },
		component: UsersComponent,
	},
	{
		path: ':userId',
		title: `User ${BASE_TITLE}`,
		resolve: { userId: usersLinkResolver },
		data: {
			breadcrumb: (data: any) => data?.userId,
		},
		component: UsersLinkComponent,
	},
];
