<div
	class="note-item"
	#item
	[@grow]="{
		value: isEditing(),
		params: { startHeight: item.clientHeight }
	}"
>
	<header class="note-item__header">
		<span class="max-w-24 truncate">{{ note().commenter }}</span>
		<span>・</span>
		<span class="text-layout-shingle-gray grow">
			{{ note().timeCommented | date: 'medium' }}
		</span>
		<button class="note-item__button" matRipple (click)="pinNote(note())">
			@if (isPinning()) {
				<csd-app-loading-icon />
			} @else {
				<mat-icon inline class="material-symbols-filled px-1">
					{{ note().pinned ? 'keep' : 'keep_off' }}
				</mat-icon>
			}
		</button>
	</header>
	@if (isEditing()) {
		<textarea class="note-item__edit" [(ngModel)]="editingText" #textarea matInput></textarea>
		<div class="flex justify-evenly gap-2 pb-3">
			<csd-app-button size="small" type="tonal" (click)="cancelEdit()">Cancel</csd-app-button>
			<csd-app-button
				size="small"
				variation="secondary"
				classList="w-24"
				[disabled]="isSaving()"
				(click)="saveEdit(note())"
				>Save
				@if (isSaving()) {
					<csd-app-loading-icon />
				}
			</csd-app-button>
		</div>
	} @else {
		<div class="note-item__text" (dblclick)="editNote(note())">
			<p>{{ note().comment }}</p>
			<button class="note-item__button note-item__button--edit" (click)="editNote(note())">
				<mat-icon inline class="material-symbols-filled px-1">edit_square</mat-icon>
			</button>
		</div>
	}
</div>
