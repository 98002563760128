<div class="flex items-center">
	@if (isEditing()) {
		<input class="name-input" type="text" matInput [(ngModel)]="text" (keydown)="onKey($event)" />
		<button class="name-action-button name-action-button--cancel">
			<mat-icon inline class="material-symbols-filled align-top" (click)="onCancel()"
				>close</mat-icon
			>
		</button>
		<button class="name-action-button name-action-button--confirm">
			<mat-icon inline class="material-symbols-filled align-top" (click)="onNameChange()"
				>check</mat-icon
			>
		</button>
	} @else {
		{{ text() }}
		<button class="name-action-button name-action-button--edit">
			<mat-icon inline class="material-symbols-filled align-sub" (click)="startEditMode()"
				>edit</mat-icon
			>
		</button>
	}
</div>
