<div class="device-restrictions">
	@for (item of device().policy.policyAndroidSettings; track item.settingId; let i = $index) {
		<div class="relative">
			<csd-app-app-checkbox
				class="w-full"
				[text]="item.setting.name"
				[checked]="getCheckedValue(item)"
				[disabled]="!item.canEdit"
				[showReset]="item.differentThanGroup && !isItemResetting()"
				(change)="changeSelection($event, $index)"
				(reset)="resetSetting(item)"
			></csd-app-app-checkbox>
			@if (item.settingId === isItemResetting()) {
				<div class="absolute bottom-2 right-3">
					<csd-app-loading-icon />
				</div>
			}
			@if (!item.canEdit) {
				<div
					class="absolute inset-0 rounded cursor-not-allowed"
					#popoverTrigger="mtxPopoverTrigger"
					[mtxPopoverTriggerFor]="popoverDisabled"
					[mtxPopoverTriggerData]="item"
				></div>
			}
		</div>
	}

	<mtx-popover #popoverDisabled="mtxPopover" [hideArrow]="true">
		<ng-template mtxPopoverContent let-name="item">
			Editing this setting is restricted by the administrator
		</ng-template>
	</mtx-popover>
</div>
