export const UPDATES_CHANNELS = [
	{
		label: 'Stable',
		value: 2,
	},
	{
		label: 'Beta',
		value: 1,
	},
	{
		label: 'Alpha',
		value: 0,
	},
];

export const FIELD_FRIENDLY_NAMES: Record<string, string> = {
	name: 'Name',
	releaseChannel: 'Channel Update',
	group: 'Policy',
	uninstallKey: 'Unlock Code',
};
