import {
	Event,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
} from '@angular/router';

export const filterNavigationTransition = (e: Event) =>
	e instanceof NavigationStart ||
	e instanceof NavigationEnd ||
	e instanceof NavigationCancel ||
	e instanceof NavigationError;
