<csd-app-banner type="danger">
	<div class="flex justify-between items-center gap-3">
		<span>
			Temporary open until {{ device().overrideFinishedUtc | date: 'h:mma MMM d, y' }}. Any changes
			on this page will only be temporary
		</span>
		<csd-app-button
			class="text-layout-stone-gray"
			type="tonal"
			size="small"
			classList="min-w-24"
			[disabled]="isLoading()"
			(click)="closeDevice()"
		>
			@if (isLoading()) {
				<csd-app-loading-icon />
			} @else {
				Close Device
			}
		</csd-app-button>
	</div>
</csd-app-banner>
