import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError, forkJoin, Observable, of } from 'rxjs';

import { PoliciesStore } from '../policies/policies.store';
import { Group } from '../policies/policies.types';
import { PolicyDetailsService } from './policy-details.service';
import { PolicyOwner } from './policy-details.types';

export const policyDetailsResolver: ResolveFn<(Group | PolicyOwner[])[]> = (route) => {
	const policiesStore = inject(PoliciesStore);
	const policyDetailsService = inject(PolicyDetailsService);
	const groupId = route.paramMap.get('groupId') as string;

	const policyDetails = policiesStore.groups()[groupId] as unknown as Group;
	const ownerOptions = policiesStore.owners()[groupId] as unknown as PolicyOwner[];

	const requestsList = <Observable<Group | PolicyOwner[]>[]>[];

	if (policyDetails && policyDetails.policy) {
		requestsList.push(of(policyDetails as Group));
	} else {
		const requestPolicy = policyDetailsService.getGroup(groupId);
		requestsList.push(requestPolicy);
	}

	if (ownerOptions) {
		requestsList.push(of(ownerOptions));
	} else {
		const requestOwners = policyDetailsService.getOwnerOptions(groupId);
		requestsList.push(requestOwners);
	}

	return forkJoin(requestsList).pipe(
		catchError(() => {
			throw new Error('Error getting policy details. Verify the group ID and try again.');
		}),
	);
};
