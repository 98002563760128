import { computed } from '@angular/core';
import { Note } from '@feature/device-details/device-details.types';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

export interface NotesState {
	notes: Record<string, Note>;
}

const notesInitialState: NotesState = {
	notes: {},
};

export const NotesStore = signalStore(
	{ providedIn: 'root' },
	withState(notesInitialState),
	withComputed((store) => ({
		notesList: computed(() =>
			Object.values(store.notes()).sort((a, b) => a.timeCommented.localeCompare(b.timeCommented)),
		),
		pinnedNotes: computed(() => Object.values(store.notes()).filter((note) => note.pinned)),
	})),
	withMethods((store) => ({
		addNote(note: Note) {
			patchState(store, (state) => ({
				...state,
				notes: {
					...state.notes,
					[note.id]: note,
				},
			}));
		},
		updateNote(noteId: string, noteUpdate: Partial<Note>) {
			patchState(store, (state) => ({
				...state,
				notes: {
					...state.notes,
					[noteId]: { ...state.notes[noteId], ...noteUpdate },
				},
			}));
		},
		setNotes(notes: Note[]) {
			patchState(store, (state) => ({
				...state,
				notes: notes.reduce(
					(acc, note) => ({
						...acc,
						[note.id]: note,
					}),
					{} as Record<string, Note>,
				),
			}));
		},
		getNote(noteId: string) {
			return store.notes()[noteId];
		},
		deleteNote(noteId: string) {
			patchState(store, (state) => {
				const notes = { ...state.notes };
				delete notes[noteId];

				return {
					...state,
					notes,
				};
			});
		},
		resetState() {
			patchState(store, notesInitialState);
		},
	})),
);
