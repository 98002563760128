import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	inject,
	input,
	signal,
	viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Note } from '@feature/device-details/device-details.types';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { finalize } from 'rxjs';

import { NotesService } from '../notes.service';
import { NotesStore } from '../notes.store';

@Component({
	selector: 'csd-app-note-item',
	standalone: true,
	imports: [DatePipe, LoadingIconComponent, ButtonComponent, FormsModule, MatIconModule],
	templateUrl: './note-item.component.html',
	styleUrl: './note-item.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('grow', [
			transition('void <=> *', []),
			transition('* <=> *', [style({ height: '{{startHeight}}px' }), animate('.2s ease')], {
				params: { startHeight: 0 },
			}),
		]),
	],
})
export class NoteItemComponent implements AfterViewChecked {
	textarea = viewChild<ElementRef>('textarea');

	#notesService = inject(NotesService);
	#notesStore = inject(NotesStore);

	note = input.required<Note>();

	isPinning = signal<boolean>(false);
	isEditing = signal<boolean>(false);
	isSaving = signal<boolean>(false);
	editingText = signal<string>('');

	ngAfterViewChecked() {
		if (this.isEditing()) {
			this.textarea()?.nativeElement.focus();
		}
	}

	cancelEdit() {
		this.isEditing.set(false);
		this.editingText.set('');
	}

	editNote(note: Note) {
		this.isEditing.set(true);
		this.editingText.set(note.comment);
	}

	pinNote(note: Note) {
		this.isPinning.set(true);
		const newNote = { ...note, pinned: !note.pinned };

		this.updateNote(newNote);
	}

	saveEdit(note: Note) {
		this.isSaving.set(true);
		const newNote = { ...note, comment: this.editingText() };
		this.updateNote(newNote);
	}

	updateNote(note: Note) {
		const { id: noteId, timeCommented, ...updatedNote } = note;

		this.#notesService
			.updateNote(noteId, updatedNote)
			.pipe(
				finalize(() => {
					this.isPinning.set(false);
					this.isSaving.set(false);
				}),
			)
			.subscribe((updatedNote) => {
				const { id: updatedNoteId, ...updatedNoteData } = updatedNote;
				this.#notesStore.updateNote(updatedNoteId, updatedNoteData);
				this.cancelEdit();
			});
	}
}
