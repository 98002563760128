import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnDestroy,
	OnInit,
	signal,
	ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CompanyGroup } from '@feature/groups/groups.types';
import { UsersService } from '@feature/users/users.service';
import { UsersStore } from '@feature/users/users.store';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { ModalService } from '@ui/modal/modal.service';
import { finalize, Subscription, switchMap } from 'rxjs';

import { DashboardComponent } from './dashboard/dashboard.component';
import { StatusComponent } from './status/status.component';
import { UsersLinkService } from './users-link.service';

@Component({
	selector: 'csd-app-users-link',
	standalone: true,
	imports: [
		DashboardComponent,
		InputSearchComponent,
		LoaderComponent,
		MatIconModule,
		MatPaginatorModule,
		MatTableModule,
		StatusComponent,
	],
	templateUrl: './users-link.component.html',
	styleUrls: ['./users-link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersLinkComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	readonly #usersStore = inject(UsersStore);
	#modalService = inject(ModalService);
	#usersLinkService = inject(UsersLinkService);
	#usersService = inject(UsersService);

	displayedColumns = ['access', 'status', 'dashboard', 'remove'];
	groups = signal<CompanyGroup[]>([]);
	filteredGroups = signal<CompanyGroup[]>([]);
	loadingGroups = signal<boolean>(false);
	subscriptions$: Subscription[] = [];
	userSelected = this.#usersStore.selectedUser;
	search = '';

	dataSource = computed<MatTableDataSource<CompanyGroup>>(() => {
		const matTableData = new MatTableDataSource(this.filteredGroups());
		matTableData.paginator = this.paginator;
		return matTableData;
	});

	ngOnInit(): void {
		this.loadingGroups.set(true);

		if (!this.#usersStore.usersList().length) {
			this.loadUsers();
			return;
		}

		const sub$ = this.loadGroups().subscribe(this.updateGroupsList.bind(this));
		this.subscriptions$.push(sub$);
	}

	ngOnDestroy(): void {
		this.subscriptions$.forEach((sub) => sub?.unsubscribe());
	}

	addNewGroup(data: CompanyGroup) {
		if (!data) {
			return;
		}

		this.groups.update((groups) => {
			return [...groups, data];
		});
		this.filterGroups(this.search);
	}

	filterGroups(searchValue: string) {
		const filteredGroups = this.groups().filter((group) => {
			const value = searchValue.toLowerCase();
			const groupName = group.groupName.toLowerCase();
			const groupAccess = group.access.toLowerCase();
			return groupName.includes(value) || groupAccess.includes(value);
		});

		this.filteredGroups.set(filteredGroups);
	}

	loadUsers() {
		this.loadingGroups.set(true);

		const sub$ = this.#usersService
			.getAuthentikUsers()
			.pipe(
				switchMap(() => this.loadGroups()),
				finalize(() => this.loadingGroups.set(false)),
			)
			.subscribe(this.updateGroupsList.bind(this));

		this.subscriptions$.push(sub$);
	}

	loadGroups() {
		return this.#usersLinkService
			.getLinks(this.#usersStore.selectedUser().sub)
			.pipe(finalize(() => this.loadingGroups.set(false)));
	}

	updateGroupsList(data: CompanyGroup[]) {
		this.groups.set(data);
		this.filteredGroups.set(data);
	}

	async openAddLinkModal() {
		await this.#modalService.open('add-link', { groups: this.groups() });
		this.#modalService.afterClose(this.addNewGroup.bind(this));
	}

	async openRemoveLinkModal(group: CompanyGroup) {
		await this.#modalService.open('remove-link', { group, user: this.userSelected() });
		this.#modalService.afterClose(this.removeGroup.bind(this));
	}

	removeGroup(group: CompanyGroup) {
		this.groups.update((groups) => {
			return groups.filter((g) => g.access !== group.access);
		});
		this.filterGroups(this.search);
	}
}
