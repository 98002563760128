import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	effect,
	inject,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { PoliciesService } from '@feature/policies/policies.service';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { DeviceSubscriptionData } from '@ui/modal/device-subscription/device-subscription.component';
import { ModalService } from '@ui/modal/modal.service';
import { NotificationService } from '@ui/notification/notification.service';
import { Subscription } from 'rxjs';

import { Customer } from '../../customer/customer.types';
import { CustomersStore } from '../../customers/customers.store';
import { DeviceDetailsService } from '../../device-details/device-details.service';
import { DevicesStore } from '../../devices/devices.store';
import { PoliciesStore } from '../../policies/policies.store';
import { GroupBase } from '../../policies/policies.types';
import { AndroidDevice } from '../device-details.types';
import { CommandsComponent } from './commands/commands.component';
import { DeviceNameComponent } from './device-name/device-name.component';
import { FIELD_FRIENDLY_NAMES, UPDATES_CHANNELS } from './stats.constants';
import { UninstallKeyComponent } from './uninstall-key/uninstall-key.component';

@Component({
	selector: 'csd-app-stats',
	standalone: true,
	imports: [
		CommandsComponent,
		DatePipe,
		DeviceNameComponent,
		HeaderDividerComponent,
		MatIconModule,
		MatOptionModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		ReactiveFormsModule,
		UninstallKeyComponent,
	],
	templateUrl: './stats.component.html',
	styleUrl: './stats.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsComponent implements OnInit, OnDestroy {
	readonly #customersStore = inject(CustomersStore);
	readonly #devicesStore = inject(DevicesStore);
	readonly #policiesStore = inject(PoliciesStore);
	#deviceDetailsService = inject(DeviceDetailsService);
	#fb = inject(FormBuilder);
	#modalService = inject(ModalService);
	#notificationService = inject(NotificationService);
	#policiesService = inject(PoliciesService);

	device = this.#devicesStore.selectedDevice;
	selectedWorkOrder = this.#devicesStore.selectedSubscription;

	updatesChannels = UPDATES_CHANNELS;
	form = this.#fb.group({
		name: this.device().name,
		releaseChannel: this.device().releaseChannel,
		group: this.device().group?.id || '',
		uninstallKey: this.device().uninstallKey || '',
	});
	groups = this.#policiesStore.allGroups;
	subscriptions: Subscription[] = [];
	qrCodeUrl: string = '';

	constructor() {
		effect(() => {
			const groupLength = this.groups().length;

			if (groupLength && groupLength > 1) {
				this.form.get('group')?.enable();
			} else {
				this.form.get('group')?.disable();
			}
		});
	}

	get baseDataToUpdate() {
		return {
			name: this.device().name,
			group: this.device().group?.id as string,
			uninstallKey: this.device().uninstallKey,
			releaseChannel: this.device().releaseChannel,
			workOrder: this.device().workOrder || this.selectedWorkOrder(),
		};
	}

	ngOnInit(): void {
		this.getPoliciesList();
		this.qrCodeUrl = this.#deviceDetailsService.getQrCode(this.device().id);

		const subscriptionGroup = this.#devicesStore.getSubscription(this.device().workOrder);
		const deviceExistsInGroup = subscriptionGroup.secureLockAndroid.find(
			(device) => device.id === this.device().id,
		);

		if (!deviceExistsInGroup) {
			this.#devicesStore.addDeviceToSubscription(this.device().workOrder, this.device());
		}
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	getPoliciesList() {
		const customer = this.#customersStore.selectedCustomer() as Customer;
		const customerCode = customer.code;
		const customerId = customer.id;

		if (this.#policiesStore.allGroups().length) {
			return;
		}

		const customer$ = this.#policiesService.getByCustomer(customerCode, customerId).subscribe();
		this.subscriptions.push(customer$);
	}

	async openWorkOrder() {
		const data: DeviceSubscriptionData = {
			id: this.device().id,
			...this.baseDataToUpdate,
		};
		await this.#modalService.open('device-subscription', data);
	}

	updateDevice(field: string) {
		const fieldValue = this.form.get(field)?.value;
		const fieldName = FIELD_FRIENDLY_NAMES[field];

		if (!fieldValue) {
			return;
		}

		const data = {
			...this.baseDataToUpdate,
			[field]: fieldValue,
		};

		const updateSub$ = this.#deviceDetailsService
			.updateDevice(this.device().id, data)
			.subscribe(() => {
				this.#notificationService.openSuccess({
					message: `<strong>${fieldName}</strong> was updated.`,
				});
				this.updateDeviceStore(this.device().id, {
					name: data.name,
					group: (this.groups() as GroupBase[])?.find((group) => group.id === data.group),
					uninstallKey: data.uninstallKey,
					releaseChannel: data.releaseChannel,
					workOrder: data.workOrder,
				});

				if (field === 'group') {
					const sub$ = this.#deviceDetailsService.getDevice(this.device().id).subscribe();
					this.subscriptions.push(sub$);
				}
			});

		this.subscriptions.push(updateSub$);
	}

	updateDeviceStore(deviceId: string, data: Partial<AndroidDevice>) {
		this.#devicesStore.updateDeviceDetail(deviceId, data);
	}

	selectCompareValue(first: string | number, second: string | number) {
		if (Number.isNaN(+first) || Number.isNaN(+second)) {
			return first === second;
		}

		return +first === +second;
	}
}
