export const CONFIG_LINKS = [
	{
		label: 'Apps',
		icon: 'apps',
		route: 'apps',
		restrictTo: ['isEmypeople', 'appsAccess'],
	},
	{
		label: 'Users',
		icon: 'groups',
		route: 'users',
		restrictTo: ['isEmypeople'],
	},
	{
		label: 'Credentials',
		icon: 'manufacturing',
		route: 'credentials',
		restrictTo: ['isEmypeople', 'adminAccess'],
	},
];
