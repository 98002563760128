import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
	signal,
	ViewChild,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { LoaderComponent } from '@root/app/ui/loader/loader.component';
import { NotificationService } from '@root/app/ui/notification/notification.service';

import { App } from './app';
import { AppsService } from './apps.service';
import { AppsStore } from './apps.store';

@Component({
	selector: 'csd-app-apps',
	standalone: true,
	imports: [
		MatTableModule,
		MatPaginatorModule,
		CommonModule,
		FormsModule,
		MatIconModule,
		MatInputModule,
		LoaderComponent,
	],
	templateUrl: './apps.component.html',
	styleUrl: './apps.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsComponent implements OnInit, AfterViewInit {
	readonly #appsStore = inject(AppsStore);
	#notificationService = inject(NotificationService);
	#appService = inject(AppsService);

	appTableColumns = ['name', 'packageName', 'required', 'directInternetRequired', 'type', 'delete'];
	isLoadingDetails = signal<boolean>(false);

	@ViewChild(MatPaginator) paginator: MatPaginator | null = null;

	apps = signal<MatTableDataSource<App>>(new MatTableDataSource());

	ngOnInit() {
		this.isLoadingDetails.set(true);
	}

	ngAfterViewInit(): void {
		if (this.#appsStore.appsList().length) {
			this.setTableData(this.#appsStore.appsList());
			return;
		}

		this.#appService.getApps().subscribe((apps) => {
			this.setTableData(apps);
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.apps().filter = filterValue.trim();
	}

	deleteApp(appId: string) {
		const result = confirm(`Are you sure?`);
		if (!result) {
			return;
		}

		this.#appService.deleteApp(appId).subscribe(() => {
			const appIndex = this.apps().data.findIndex((app) => app.id === appId);
			this.apps().data.splice(appIndex, 1);
			this.apps()._updateChangeSubscription();
			this.#notificationService.openSuccess({
				message: 'Successfully deleted app',
			});
			this.#appsStore.removeApp(appId);
		});
	}

	setTableData(data: App[]) {
		const matTableData = new MatTableDataSource(data);
		matTableData.paginator = this.paginator;
		this.apps.set(matTableData);
		this.isLoadingDetails.set(false);
	}

	updateApp(event: Event, app: App) {
		app.required = (event.target as HTMLSelectElement).value === 'true';

		this.#appService.updateApp(app).subscribe(() => {
			this.#notificationService.openSuccess({
				message: `Successfully updated ${app.name}`,
			});
		});
	}

	setDirectInternetRequired(event: Event, app: App) {
		app.directInternetRequired = (event.target as HTMLSelectElement).value === 'true';

		this.#appService.updateApp(app).subscribe(() => {
			this.#notificationService.openSuccess({
				message: `Successfully updated ${app.name}`,
			});
			this.#appsStore.updateApp(app.id, app);
		});
	}
}
