import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
	OnDestroy,
	signal,
} from '@angular/core';
import { ButtonComponent } from '@ui/button/button.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, finalize, Subscription } from 'rxjs';

import { DeviceDetailsService } from '../../device-details.service';
import { ANDROID_COMMANDS, COMMAND_BUTTONS } from './commands.constants';

@Component({
	selector: 'csd-app-commands',
	standalone: true,
	imports: [ButtonComponent],
	templateUrl: './commands.component.html',
	styleUrl: './commands.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandsComponent implements OnDestroy {
	#deviceDetailsService = inject(DeviceDetailsService);
	#notificationService = inject(NotificationService);

	deviceId = input.required<string>();
	buttonLoading = signal<number | null>(null);
	subscriptions: Subscription[] = [];

	commandButtons = computed(() =>
		COMMAND_BUTTONS.map((el, index) => ({
			...el,
			isLoading: index === this.buttonLoading(),
		})),
	);

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	onCommand(index: number) {
		if (!this.deviceId()) {
			throw new Error('No device id found');
		}

		this.sendCommand(index);
	}

	sendCommand(index: number) {
		const commandButton = COMMAND_BUTTONS[index];
		const commandNumber = ANDROID_COMMANDS[commandButton.command];
		const commandLabel = commandButton.label;

		this.buttonLoading.set(index);

		const commandSub$ = this.#deviceDetailsService
			.sendCommand(this.deviceId(), commandNumber)
			.pipe(
				finalize(() => this.buttonLoading.set(null)),
				catchError(() => {
					throw new Error(`Error updating device with command: ${commandLabel}`);
				}),
			)
			.subscribe(() => {
				this.#notificationService.openSuccess({
					message: `Successfully applied command <strong>${commandLabel}</strong> to the device`,
				});
			});

		this.subscriptions.push(commandSub$);
	}
}
