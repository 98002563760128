import { CompanyGroup } from '@feature/groups/groups.types';
import { CONFIG_LINKS } from '@layout/configuration-layout/configuration-layout.constants';

export const CUSTOMER_BUTTONS = [
	{
		icon: 'dvr',
		path: 'overview',
		label: 'Overview',
	},
	{
		icon: 'devices',
		path: 'devices',
		label: 'Devices',
	},
];

export const CONFIG_BUTTONS: {
	icon: string;
	path: string;
	label: string;
	roles?: string[];
	restrictTo?: (keyof CompanyGroup)[];
}[] = [
	{
		icon: 'settings',
		path: 'configuration',
		label: 'Configuration',
		restrictTo: [
			...new Set(CONFIG_LINKS.map((link) => link.restrictTo).flat()),
		] as (keyof CompanyGroup)[],
	},
	{
		icon: 'business_center',
		path: '{customer}/policies',
		label: 'Policies',
	},
];
