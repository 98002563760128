import {
	CdkDrag,
	CdkDragDrop,
	CdkDragHandle,
	CdkDragPlaceholder,
	CdkDropList,
	moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
	output,
	signal,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TagComponent } from '@ui/tag/tag.component';

import { PoliciesStore } from '../policies.store';
import { SortableItem, SortableList, SortableTagType } from './sortable-list.types';

@Component({
	selector: 'csd-app-sortable-list',
	standalone: true,
	imports: [
		CdkDrag,
		CdkDragHandle,
		CdkDragPlaceholder,
		CdkDropList,
		MatIconModule,
		MatRippleModule,
		RouterLink,
		TagComponent,
	],
	templateUrl: './sortable-list.component.html',
	styleUrl: './sortable-list.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableListComponent {
	readonly #policiesStore = inject(PoliciesStore);

	ownerColorVariation: Record<string, string> = {
		user: 'bg-tag-user text-white',
		church: 'bg-tag-church text-white',
		global: 'bg-tag-global text-white',
	};

	itemsList = input.required<SortableList>();
	disableDrop = input<boolean>(false);
	itemDeleted = output<SortableItem>();

	innerSort = signal<SortableList>([]);
	list = computed(() => (this.innerSort.length ? this.innerSort() : this.itemsList()));

	drop(event: CdkDragDrop<number[]>) {
		if (!this.innerSort().length) {
			this.innerSort.set(this.itemsList());
		}

		moveItemInArray(this.innerSort(), event.previousIndex, event.currentIndex);
	}

	getColorVariation(type: SortableTagType) {
		return this.ownerColorVariation[type] || this.ownerColorVariation['user'];
	}

	onDelete(event: Event, item: SortableItem) {
		event.preventDefault();
		event.stopImmediatePropagation();
		this.itemDeleted.emit(item);
	}

	selectPolicy(item: SortableItem) {
		this.#policiesStore.updateSelectedGruopId(item.id);
	}
}
