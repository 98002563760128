import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { ConfigService } from './config.service';

export const configResolver: ResolveFn<boolean> = (route, state) => {
	const configService = inject(ConfigService);

	return configService.canAccessConfigurationRoute(state.url);
};
