<mat-select
	class="status-dropdown"
	hideSingleSelectionIndicator
	[value]="group().status"
	(selectionChange)="onSelectChange($event.value)"
>
	@for (item of userStatusOptions; track item.label) {
		<mat-option [value]="item.value">{{ item.label }}</mat-option>
	}
</mat-select>
