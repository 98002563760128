<csd-app-loader [isLoading]="loadingGroups()">
	<section class="users-link-header">
		<div class="w-full sm:w-80">
			<csd-app-input-search (changed)="filterGroups($event)" [(search)]="search" />
		</div>
		<button class="add-link-button" type="button" (click)="openAddLinkModal()">
			<mat-icon inline class="align-bottom">add</mat-icon> Add link
		</button>
	</section>

	<table mat-table [dataSource]="dataSource()" class="mdc-data-table--plain">
		<!-- Access Column -->
		<ng-container matColumnDef="access">
			<th mat-header-cell *matHeaderCellDef>Access</th>
			<td mat-cell *matCellDef="let element">{{ element?.groupName || element?.access }}</td>
		</ng-container>

		<!-- Status Column -->
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef>Status</th>
			<td mat-cell *matCellDef="let element">
				<csd-app-status [group]="element" [userId]="userSelected().sub" />
			</td>
		</ng-container>

		<!-- Dashboard Column -->
		<ng-container matColumnDef="dashboard">
			<th mat-header-cell *matHeaderCellDef>Dashboard</th>
			<td mat-cell *matCellDef="let element">
				<csd-app-dashboard [group]="element" [userId]="userSelected().sub" />
			</td>
		</ng-container>

		<!-- Remove Column -->
		<ng-container matColumnDef="remove">
			<th mat-header-cell *matHeaderCellDef>Remove</th>
			<td mat-cell *matCellDef="let element">
				<button class="text-sm px-3 py-2" (click)="openRemoveLinkModal(element)">
					<mat-icon inline class="leading-none align-middle">cancel</mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</csd-app-loader>
