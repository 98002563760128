import { Injectable } from '@angular/core';

import { AndroidDevice } from '../device-details.types';
import { InternetComponent } from './internet.component';
import { InternetFormShape, VpnFilters } from './internet.types';

@Injectable({ providedIn: InternetComponent })
export class InternetService {
	buildFormData(formValue: InternetFormShape, device: AndroidDevice) {
		return {
			name: device.name,
			group: device.group?.id,
			uninstallKey: device.uninstallKey,
			proxyConfigURL: formValue.vpn ? device.proxyConfigURL : formValue.proxyPacUrl,
			proxyConfigOn: !formValue.vpn,
			proxyExceptions: formValue.proxyExceptions?.length
				? formValue.proxyExceptions
				: device.proxyExceptions,
			browserProxyConfigURL: formValue.vpn ? device.browserProxyConfigURL : formValue.browserUrl,
			browserProxyOn: !formValue.vpn,
			browserProxyExceptions: formValue.browserProxyExceptions?.length
				? formValue.browserProxyExceptions
				: device.browserProxyExceptions,
			dnsType: formValue.dnsType,
			vpnOn: formValue.vpn,
			vpnProxy: formValue.vpn ? formValue.vpnProxy : device.vpnProxy ?? VpnFilters.Direct,
			releaseChannel: device.releaseChannel,
			workOrder: device.workOrder,
		};
	}

	prepareDeviceToStore(data: Partial<AndroidDevice>) {
		return {
			name: data.name,
			dnsType: data.dnsType,
			proxyConfigURL: data.proxyConfigURL as string | undefined,
			proxyExceptions: data.proxyExceptions,
			browserProxyConfigURL: data.browserProxyConfigURL as string | undefined,
			browserProxyExceptions: data.browserProxyExceptions,
			vpnOn: data.vpnOn as boolean,
			vpnProxy: data.vpnProxy as number | undefined,
			vpnIp: data.vpnIp as string | undefined,
		};
	}
}
