import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ConfigStore } from '@feature/config/config.store';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';
import { AppOptions } from '@root/app/shared/apps.types';
import { AppItemComponent } from '@ui/app-item/app-item.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError } from 'rxjs/operators';

import { AppsService, DeviceAppsUpdate } from '../apps.service';
import { EMPTY_MESSAGE } from './apps-list.constants';

export interface AppChangedEvent {
	appId: string;
	data: Partial<AndroidAppConfig>;
}

@Component({
	selector: 'csd-app-apps-list',
	standalone: true,
	imports: [AppItemComponent, EmptyStateComponent, MatIconModule],
	templateUrl: './apps-list.component.html',
	styleUrl: './apps-list.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsListComponent {
	#appsService = inject(AppsService);
	#configStore = inject(ConfigStore);
	#destroyRef = inject(DestroyRef);
	#notificationService = inject(NotificationService);

	appsList = input<AndroidAppConfig[]>([]);
	appOptions = this.#configStore.appOptions;
	deviceId = input<string>('');
	listType = input<keyof typeof EMPTY_MESSAGE>('launcher');
	vpnOn = input<boolean>(false);

	appChanged = output<AppChangedEvent>();

	emptyMessage = computed(() => {
		return EMPTY_MESSAGE[this.listType()];
	});

	getAppStatusOptions(app: AndroidAppConfig) {
		const { appStatuses = {} } = this.appOptions() as AppOptions;
		const leastRestrictive = app.leastRestrictiveAppStatusAllowed;
		const currentOption = app.status;

		return this.filterOptions(appStatuses, leastRestrictive, currentOption);
	}

	getVpnOptions(app: AndroidAppConfig) {
		const { vpnOptions = {} } = this.appOptions() as AppOptions;
		const leastRestrictive = app.leastRestrictiveInternetConfigAllowed;
		const currentOption = app.internetConfig;

		return this.filterOptions(vpnOptions, leastRestrictive, currentOption);
	}

	filterOptions(options: Record<string, string>, leastRestrictive: number, currentOption?: number) {
		return Object.keys(options)
			.filter(
				(optionNumber) => +optionNumber === currentOption || +optionNumber >= leastRestrictive,
			)
			.reduce(
				(newOptions, optionNumber) => ({ ...newOptions, [optionNumber]: options[optionNumber] }),
				<Record<string, string>>{},
			);
	}

	notifyUpdateStarted(message: string) {
		this.#notificationService.open({ message });
	}

	notifyUpdateSuccess(message: string) {
		this.#notificationService.openSuccess({ message });
	}

	updateStatus(newAppData: DeviceAppsUpdate, app: AndroidAppConfig) {
		const appName = app.appResponse.name;
		this.notifyUpdateStarted(`Updating <strong>${appName}</strong>`);

		this.#appsService
			.updateStatus(this.deviceId(), newAppData)
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				catchError((err) => {
					throw new Error(err.error?.title || err.message);
				}),
			)
			.subscribe((response) => {
				const { appId, internetConfig, status, differentThanGroup } = response;
				const dataToUpdateLocally = { status, internetConfig, differentThanGroup };
				this.appChanged.emit({ appId, data: dataToUpdateLocally });
				this.notifyUpdateSuccess(`App policy for <strong>${appName}</strong> was updated`);
			});
	}

	resetAppConfig(app: AndroidAppConfig) {
		const deviceId = this.deviceId();
		const appName = app.appResponse.name;
		this.notifyUpdateStarted(`Resetting config for <strong>${appName}</strong>...`);

		this.#appsService
			.removeAppValues(deviceId, app.appId)
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe((response) => {
				const { internetConfig, status, differentThanGroup } = response || {};
				const dataToUpdateLocally = { status, internetConfig, differentThanGroup };

				this.appChanged.emit({ appId: app.appId, data: dataToUpdateLocally });
				this.notifyUpdateSuccess(`Config for app ${appName} was reset successfully`);
			});
	}
}
