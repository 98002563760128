import { DeviceSettingsUpdate } from '@feature/device-details/device-details.service';
import { AndroidSetting } from '@root/app/shared/android-settings.types';

import { GroupRestrictionsUpdate } from '../../policy-details.service';

export const mapRestrictionsUpdate = (data: AndroidSetting[]): DeviceSettingsUpdate[] => {
	return data.map(
		(el): GroupRestrictionsUpdate => ({
			settingId: el.settingId,
			setting: {
				androidValue: el.setting.androidValue as string,
			},
			value: el.value,
		}),
	);
};
