import { ChangeDetectionStrategy, Component, input, model, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
	selector: 'csd-app-input-search',
	standalone: true,
	imports: [FormsModule, MatIconModule, MatInputModule, MatRippleModule],
	templateUrl: './input-search.component.html',
	styleUrl: './input-search.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSearchComponent {
	placeholder = input<string>('Search');
	search = model<string>('');
	changed = output<string>();

	onChange() {
		this.changed.emit(this.search());
	}

	clearSearch() {
		this.search.set('');
		this.changed.emit('');
	}
}
