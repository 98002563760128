@if (isEditing()) {
	<input
		class="name-input"
		[class.name-input--error]="hasGroupNameError()"
		type="text"
		matInput
		[disabled]="isLoading()"
		[(ngModel)]="groupName"
	/>
	<button class="name-action-button name-action-button--cancel">
		<mat-icon inline class="material-symbols-filled align-top" (click)="onCancel()">close</mat-icon>
	</button>
	<button class="name-action-button name-action-button--confirm">
		<mat-icon inline class="material-symbols-filled align-top" (click)="onNameChange()">
			check
		</mat-icon>
	</button>
} @else {
	<h2 class="name-header">
		{{ group()?.groupName }}
		<button class="name-action-button name-action-button--edit">
			<mat-icon class="material-symbols-filled align-sub" inline (click)="isEditing.set(true)">
				edit
			</mat-icon>
		</button>
	</h2>
}
