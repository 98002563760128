import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { GroupsStore } from '@feature/groups/groups.store';
import { CompanyGroup } from '@feature/groups/groups.types';
import { UserService } from '@feature/user/user.service';
import { UserStore } from '@feature/user/user.store';
import { Observable, of } from 'rxjs';

export const userResolver: ResolveFn<Observable<CompanyGroup[]>> = () => {
	const userStore = inject(UserStore);
	const groupsStore = inject(GroupsStore);
	const userService = inject(UserService);

	if (groupsStore.groupsList()?.length) {
		return of(groupsStore.groupsList());
	}

	return userService.getListContent(userStore.user()?.sub as string);
};
