import { formatDate } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	OnInit,
	signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DevicesStore } from '@feature/devices/devices.store';
import { UserStore } from '@feature/user/user.store';
import { DATE_FORMAT } from '@root/app/app.constants';
import { LoaderComponent } from '@ui/loader/loader.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { finalize } from 'rxjs';

import { NoteItemComponent } from './note-item/note-item.component';
import { NotesService, NotesUpdate } from './notes.service';
import { NotesStore } from './notes.store';

@Component({
	selector: 'csd-app-notes',
	standalone: true,
	imports: [
		FormsModule,
		LoaderComponent,
		LoadingIconComponent,
		MatCheckboxModule,
		MatIconModule,
		MatInputModule,
		MatRippleModule,
		NoteItemComponent,
		ReactiveFormsModule,
	],
	templateUrl: './notes.component.html',
	styleUrl: './notes.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnInit {
	readonly #devicesStore = inject(DevicesStore);
	readonly #userStore = inject(UserStore);
	#destroyRef = inject(DestroyRef);
	#formBuilder = inject(FormBuilder);
	#notesService = inject(NotesService);
	#notesStore = inject(NotesStore);

	editingText = signal<string>('');
	deviceId = this.#devicesStore.selectedDeviceId;
	form = this.#formBuilder.group({
		comment: ['', [Validators.required, Validators.minLength(1)]],
		pinned: [false],
	});
	isLoadingData = signal(false);
	isSaving = signal(false);
	notes = this.#notesStore.notesList;

	ngOnInit(): void {
		this.loadNotes();
	}

	loadNotes() {
		this.isLoadingData.set(true);

		this.#notesService
			.loadNotes(this.deviceId())
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				finalize(() => this.isLoadingData.set(false)),
			)
			.subscribe();
	}

	onSubmitNote() {
		const commentValue = this.form.controls.comment.value?.trim();
		const pinnedValue = this.form.controls.pinned.value;

		if (this.form.invalid || !commentValue) {
			return;
		}

		const formData = {
			identifier: this.deviceId(),
			commenter: this.#userStore.user()?.name,
			comment: commentValue,
			pinned: !!pinnedValue,
		};

		this.isSaving.set(true);
		this.form.disable();
		this.saveNote(formData);
	}

	onSavingNoteSuccess(noteId: string) {
		const clearId = noteId.replace(/("|')/gi, '');
		const commentValue = this.form.controls.comment.value?.trim();
		const pinnedValue = this.form.controls.pinned.value;

		this.form.enable();
		this.form.reset({ pinned: false });

		this.#notesStore.addNote({
			id: clearId,
			identifier: this.deviceId(),
			commenter: this.#userStore.user()?.name,
			comment: commentValue as string,
			timeCommented: formatDate(new Date(), DATE_FORMAT, 'en-US', 'UTC'),
			pinned: !!pinnedValue,
		});
	}

	saveNote(data: NotesUpdate) {
		this.#notesService
			.saveNote(data)
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				finalize(() => this.isSaving.set(false)),
			)
			.subscribe(this.onSavingNoteSuccess.bind(this));
	}
}
