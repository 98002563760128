import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnDestroy,
	signal,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { AndroidSetting } from '@root/app/shared/android-settings.types';
import { AppCheckboxComponent } from '@ui/app-checkbox/app-checkbox.component';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, finalize, Subscription } from 'rxjs';

import { AndroidDevice } from '../../device-details/device-details.types';
import { DevicesStore } from '../../devices/devices.store';
import { PoliciesStore } from '../../policies/policies.store';
import { PolicyDetailsService } from '../policy-details.service';
import { Policy } from '../policy-details.types';
import { mapRestrictionsUpdate } from './utils/map-restrictions-update';

@Component({
	selector: 'csd-app-restrictions',
	standalone: true,
	imports: [AppCheckboxComponent, ButtonComponent, LoadingIconComponent, RouterLink],
	templateUrl: './restrictions.component.html',
	styleUrl: './restrictions.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictionsComponent implements OnDestroy {
	readonly #devicesStore = inject(DevicesStore);
	readonly #policiesStore = inject(PoliciesStore);
	#notificationService = inject(NotificationService);
	#policyDetailsService = inject(PolicyDetailsService);

	currentGroup = this.#policiesStore.selectedGroup;
	isLoading = signal<boolean>(false);
	restrictions = computed(() => {
		return this.currentGroup()?.policy?.policyAndroidSettings || [];
	});
	restrictionsToUpdate = signal<AndroidSetting[]>([...this.restrictions()]);
	subscriptions: Subscription[] = [];

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	changeSelection(value: boolean, index: number) {
		const policyAndroidSettings = [...this.restrictionsToUpdate()];
		const policyToUpdate = { ...policyAndroidSettings[index], value };

		policyAndroidSettings[index] = policyToUpdate;
		this.restrictionsToUpdate.set(policyAndroidSettings);
	}

	forceDeviceUpdate() {
		Object.values(this.#devicesStore.devices()).forEach((device) => {
			if ((device as AndroidDevice).policy) {
				this.#devicesStore.updateDeviceDetail(device.id, { policy: undefined });
			}
		});
	}

	handleSuccessUpdate() {
		this.#policiesStore.updateGroupDetails(this.currentGroup()?.id as string, {
			policy: {
				...(this.currentGroup()?.policy as Policy),
				policyAndroidSettings: this.restrictionsToUpdate(),
			},
		});
		this.#notificationService.openSuccess({
			message: 'Group restrictions were updated successfully',
		});
		this.forceDeviceUpdate();
	}

	onSaveAndPush() {
		const shouldPush = true;
		this.updateRestrictions(shouldPush);
	}

	onSave() {
		this.updateRestrictions();
	}

	updateRestrictions(shouldPush: boolean = false) {
		this.isLoading.set(true);

		const data = mapRestrictionsUpdate(this.restrictionsToUpdate());
		const update$ = this.#policyDetailsService.updateRestrictions(
			this.currentGroup()?.id as string,
			data,
			shouldPush,
		);

		const withPipe$ = update$.pipe(
			finalize(() => this.isLoading.set(false)),
			catchError(() => {
				throw new Error(
					'An error occurred while trying to save your restrictions changes. Please try again later.',
				);
			}),
		);

		const sub$ = withPipe$.subscribe(this.handleSuccessUpdate.bind(this));

		this.subscriptions.push(sub$);
	}
}
