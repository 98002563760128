<div class="users-comp">
	<csd-app-loader [isLoading]="loadingUsers()">
		<section class="w-full md:w-80 pb-5">
			<csd-app-input-search (changed)="filterUsers($event)"></csd-app-input-search>
		</section>

		<table mat-table [dataSource]="dataSource()" class="mdc-data-table--with-link">
			<!-- Name Column -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef width="35%">Name</th>
				<td mat-cell *matCellDef="let element" width="35%">{{ element?.name }}</td>
			</ng-container>

			<!-- Username Column -->
			<ng-container matColumnDef="username">
				<th mat-header-cell *matHeaderCellDef width="35%">Username</th>
				<td mat-cell *matCellDef="let element" width="35%">{{ element?.userName }}</td>
			</ng-container>

			<!-- Email Column -->
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef width="30%">Email</th>
				<td mat-cell *matCellDef="let element" width="30%">{{ element?.email }}</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="setUser(row)"></tr>
		</table>

		<div class="table-pagination-wrapper">
			<mat-paginator
				[pageSizeOptions]="[10]"
				[showFirstLastButtons]="false"
				[pageSize]="10"
				hidePageSize
				aria-label="Select page of customers"
			>
			</mat-paginator>
		</div>
	</csd-app-loader>
</div>
