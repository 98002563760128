import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { CompanyGroup } from '@feature/groups/groups.types';
import { UserService } from '@feature/user/user.service';
import { SectionMenuComponent, SectionMenuItem } from '@ui/section-menu/section-menu.component';
import { isNavigationEnd } from '@utils/routing/is-navigation-end';
import { Subscription } from 'rxjs';

import { CONFIG_LINKS } from './configuration-layout.constants';

@Component({
	selector: 'csd-app-configuration-layout',
	standalone: true,
	imports: [RouterOutlet, SectionMenuComponent],
	templateUrl: './configuration-layout.component.html',
	styleUrl: './configuration-layout.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationLayoutComponent implements OnInit, OnDestroy {
	readonly #userService = inject(UserService);
	#router = inject(Router);
	#selectedGroup = this.#userService.userSelectedGroup;

	activeSection = signal<string>('');
	routerSub$!: Subscription;

	configLinks = computed<SectionMenuItem[]>(() => {
		return CONFIG_LINKS.filter((el) => {
			if (!el.restrictTo) {
				return true;
			}

			return el.restrictTo.some((role) => this.#selectedGroup()?.[role as keyof CompanyGroup]);
		}).map((link) => ({
			...link,
			active: this.activeSection() === link.route,
		}));
	});

	ngOnInit(): void {
		this.routerSub$ = this.#router.events.pipe(isNavigationEnd()).subscribe((event) => {
			this.activeSection.set(event.urlAfterRedirects.split('/')[2]);
		});
	}

	ngOnDestroy(): void {
		this.routerSub$?.unsubscribe();
	}
}
