import { inject, Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { CompanyGroup, CompanyGroupUserRole } from '@feature/groups/groups.types';
import { NotificationService } from '@ui/notification/notification.service';
import { tap } from 'rxjs';

export type UpdateGroupPayload = {
	status: CompanyGroupUserRole;
	dashboard: boolean;
	userSub: string;
};

@Injectable({ providedIn: 'root' })
export class UsersLinkService {
	#apiService = inject(ApiService);
	#notificationService = inject(NotificationService);

	getLinks(userId: string) {
		return this.#apiService.get<CompanyGroup[]>(`/access/listcontent/${userId}`);
	}

	updateGroup(access: string, group: UpdateGroupPayload) {
		this.#notificationService.open({ message: 'Updating group...' });

		return this.#apiService
			.put<void>(`/access/giveaccesstogroup/${access}`, group)
			.pipe(
				tap(() => this.#notificationService.openSuccess({ message: `Group updated successfully` })),
			);
	}
}
