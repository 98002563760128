import { Routes } from '@angular/router';
import configRoutes from '@feature/config/config.routes';
import { userResolver } from '@feature/user/user.resolver';
import { NotAllowedLayoutComponent } from '@layout/not-allowed-layout/not-allowed-layout.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

import { BASE_TITLE } from './app.constants';
import authRoutes from './feature/auth/auth.routes';
import { customerResolver } from './feature/customer/customer.resolver';
import customerRoutes from './feature/customer/customer.routes';
import customersRoutes from './feature/customers/customers.routes';
import devicesRoutes from './feature/devices/devices.routes';
import { groupDetailRoutes } from './feature/group-detail/group-detail.routes';
import { ErrorLayoutComponent } from './layout/error-layout/error-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { OnboardingLayoutComponent } from './layout/onboarding-layout/onboarding-layout.component';
import { PoliciesLayoutComponent } from './layout/policies-layout/policies-layout.component';

export const appRoutes: Routes = [
	{
		path: 'authentication',
		component: LoginLayoutComponent,
		title: `Authenticate ${BASE_TITLE}`,
		children: authRoutes,
	},
	{
		path: 'onboarding',
		component: OnboardingLayoutComponent,
		title: `Onboarding ${BASE_TITLE}`,
		loadChildren: () => import('./feature/onboarding/onboarding.routes'),
		canActivate: [AutoLoginPartialRoutesGuard],
	},
	{
		path: 'policies',
		redirectTo: 'customer/public/policies',
		pathMatch: 'full',
	},
	{
		path: '',
		component: MainLayoutComponent,
		title: `Control Dashboard ${BASE_TITLE}`,
		canActivate: [AutoLoginPartialRoutesGuard],
		resolve: { data: userResolver },
		children: [
			{
				path: '',
				children: customersRoutes,
			},
			{
				path: 'not-found',
				component: ErrorLayoutComponent,
			},
			{
				path: 'not-allowed',
				component: NotAllowedLayoutComponent,
			},
			{
				path: 'customer/:customerId',
				resolve: { customer: customerResolver },
				data: {
					breadcrumb: (data: any) => {
						return data?.customer?.[0] ? data?.customer?.[0]?.code?.toUpperCase() : '';
					},
				},
				children: [
					{
						path: '',
						redirectTo: 'overview',
						pathMatch: 'full',
					},
					{
						path: 'overview',
						title: `Overview ${BASE_TITLE}`,
						data: { breadcrumb: 'Overview' },
						children: customerRoutes,
					},
					{
						path: 'devices',
						title: `Devices ${BASE_TITLE}`,
						data: { breadcrumb: 'Devices' },
						children: devicesRoutes,
					},
					{
						path: 'policies',
						component: PoliciesLayoutComponent,
						data: { breadcrumb: 'Policies' },
						loadChildren: () => import('./feature/policies/policies.routes'),
					},
				],
			},
			...groupDetailRoutes,
			...configRoutes,
		],
	},
	{
		path: '**',
		redirectTo: 'not-found',
	},
];
