export enum VpnFilters {
	Direct = 0,
	SecureLock = 100,
	SafeSentry = 200,
}

export interface InternetFormShape {
	vpn: boolean | null;
	browserUrl: string | null;
	browserProxyExceptions: string[] | null;
	proxyPacUrl: string | null;
	proxyExceptions: string[] | null;
	vpnProxy: VpnFilters | null;
}
