import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { isNavigationEnd } from '@utils/routing/is-navigation-end';
import { Subscription } from 'rxjs';

interface BreadcrumbItem {
	label: string;
	url: string;
}

@Component({
	selector: 'csd-app-breadcrumb',
	standalone: true,
	imports: [MatIconModule, RouterLink],
	templateUrl: './breadcrumb.component.html',
	styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	private router = inject(Router);
	private activatedRoute = inject(ActivatedRoute);

	breadcrumbs: BreadcrumbItem[] = [];
	subscriptions: Subscription[] = [];

	ngOnInit(): void {
		this.updateOnRouterChange();
		this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	updateOnRouterChange() {
		const router$ = this.router.events.pipe(isNavigationEnd()).subscribe(() => {
			this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
		});

		this.subscriptions.push(router$);
	}

	private createBreadcrumbs(
		route: ActivatedRoute,
		url: string = '',
		breadcrumbs: BreadcrumbItem[] = [],
	): BreadcrumbItem[] {
		const children: ActivatedRoute[] = route.children;

		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
			if (routeURL !== '') {
				url += `/${routeURL}`;
			}

			const label = this.getLabel(child.snapshot.data);

			if (label) {
				breadcrumbs.push({ label, url });
			}

			return this.createBreadcrumbs(child, url, breadcrumbs);
		}

		return breadcrumbs;
	}

	private getLabel(data: any): string | null {
		let label = data?.breadcrumb;

		if (typeof data?.breadcrumb === 'function') {
			label = data.breadcrumb(data);
		}

		if (!label) {
			return null;
		}

		const maxLength = 20;
		const isMaxLength = label.length > maxLength;

		return isMaxLength ? this.splitText(label) : label;
	}

	private splitText(text: string) {
		const sectionLength = 9;
		return text.substring(0, sectionLength) + ' ... ' + text.substring(text.length - sectionLength);
	}
}
