export const EMPTY_MESSAGE = {
	launcher: {
		icon: 'rocket_launch',
		message: 'No launcher apps found matching your search.',
	},
	system: {
		icon: 'apk_document',
		message: 'No system apps found matching your search.',
	},
};
