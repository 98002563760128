<header class="device-header">
	<csd-app-name [text]="deviceName()" (changed)="onChangeName($event)" class="w-full xs:w-auto" />
	<button type="button" class="device-header__notes-button" matRipple (click)="openNotes()">
		<mat-icon inline class="align-middle leading-none max-w-fit">chevron_left</mat-icon> All Notes
	</button>

	@if (pinnedNotes().length) {
		<div class="w-full">
			<csd-app-pinned-notes [notes]="pinnedNotes()" />
		</div>
	}
</header>
