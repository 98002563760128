import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '@ui/button/button.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { DeletePolicyResponse } from '@ui/modal/delete-policy/delete-policy.component';
import { ModalService } from '@ui/modal/modal.service';
import { NotificationService } from '@ui/notification/notification.service';
import { SectionComponent } from '@ui/section/section.component';
import { catchError, Subscription } from 'rxjs';

import { CustomersStore } from '../customers/customers.store';
import { PolicyDetailsService } from './../policy-details/policy-details.service';
import { PoliciesService } from './policies.service';
import { PoliciesStore } from './policies.store';
import { GroupBase } from './policies.types';
import { SortableListComponent } from './sortable-list/sortable-list.component';
import { SortableItem, SortableTagType } from './sortable-list/sortable-list.types';

@Component({
	selector: 'csd-app-policies',
	standalone: true,
	imports: [
		ButtonComponent,
		EmptyStateComponent,
		LoaderComponent,
		MatIconModule,
		RouterLink,
		SectionComponent,
		SortableListComponent,
	],
	templateUrl: './policies.component.html',
	styleUrl: './policies.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoliciesComponent implements OnInit, OnDestroy {
	readonly #customersStore = inject(CustomersStore);
	readonly #policiesStore = inject(PoliciesStore);
	#modalService = inject(ModalService);
	#notificationService = inject(NotificationService);
	#policiesService = inject(PoliciesService);
	#policyDetailsService = inject(PolicyDetailsService);
	#router = inject(Router);

	customer = this.#customersStore.selectedCustomer;
	isLoading = this.#policiesService.isLoading;
	isLoadingDetails = this.#policyDetailsService.isLoadingDetails;
	lengthToExpand = 3;
	selectedPolicyName = signal<string>('');
	subscriptions: Subscription[] = [];

	customerPolicies = computed(() => {
		const customerGroups = this.#policiesStore.customerGroups();

		if (!(customerGroups && customerGroups.length)) {
			return [];
		}

		return customerGroups.map((item) => ({
			...item,
			link: `./${item?.id}`,
			tag: this.getTagData(item, true),
		}));
	});
	publicPolicies = computed(() => {
		const publicGroups = this.#policiesStore.publicGroups();

		if (!(publicGroups && publicGroups.length)) {
			return [];
		}

		return publicGroups.map((item) => ({
			...item,
			link: `./${item?.id}`,
			tag: this.getTagData(item),
		}));
	});

	ngOnInit(): void {
		const routeSegments = this.#router.url.split('/').filter((el) => el);
		const [customerSection, customerCode] = routeSegments;
		const isPublicRoute = customerCode === 'public';
		const hasPolicies = this.customerPolicies().length || this.publicPolicies().length;

		if (hasPolicies) {
			return;
		}

		if (isPublicRoute) {
			this.loadPublicPolicies();
			return;
		}

		this.loadCustomerPolicies();
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	async deletePolicy(item: SortableItem, section: 'customer' | 'public') {
		await this.#modalService.open('delete-policy', {
			policy: item,
			customer: this.customer(),
			section,
		});
		this.#modalService.afterClose(this.handleCloseModal.bind(this));
		this.selectedPolicyName.set(item.name);
	}

	handleCloseModal(data?: DeletePolicyResponse) {
		const policyName = this.selectedPolicyName();

		this.#modalService.close();
		this.selectedPolicyName.set('');

		if (!data) {
			return;
		}

		if (data.section === 'customer') {
			this.#policiesStore.removeCustomerGroup(data.id);
		} else {
			this.#policiesStore.removePublicGroup(data.id);
		}

		this.#notificationService.openSuccess({
			message: `Policy <strong>${policyName}</strong> was deleted successfully`,
		});
	}

	loadCustomerPolicies() {
		const sub$ = this.#policiesService
			.getByCustomer(this.customer()?.code ?? '', this.customer()?.id ?? '')
			.pipe(
				catchError(() => {
					this.#notificationService.openError({
						message: 'An error occurred while trying to load policies. Please try again later.',
					});

					return [];
				}),
			)
			.subscribe();

		this.subscriptions.push(sub$);
	}

	loadPublicPolicies() {
		const sub$ = this.#policiesService.getPublicPolicies().subscribe();

		this.subscriptions.push(sub$);
	}

	getTagData(item: GroupBase, isOwner: boolean = false) {
		if (isOwner || item?.customUser) {
			return {
				name: isOwner ? (this.customer()?.code || '').toUpperCase() : 'Custom',
				type: 'user' as SortableTagType,
			};
		}

		if (item?.churchCode) {
			return { name: item.churchCode, type: 'church' as SortableTagType };
		}

		return {
			name: 'Global',
			type: 'global' as SortableTagType,
		};
	}
}
