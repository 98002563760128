import { computed, inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { LocalStorageManagerService } from '@core/local-storage-manager/local-storage-manager.service';
import { delay, finalize, tap } from 'rxjs';

import { GroupsStore } from '../groups/groups.store';
import { CompanyGroup } from '../groups/groups.types';
import { STORAGE_SELECTED_GROUP_KEY } from './user.constants';
import { UserStore } from './user.store';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	readonly #groupsStore = inject(GroupsStore);
	readonly #userStore = inject(UserStore);
	#apiService = inject(ApiService);
	#localStorageManager = inject(LocalStorageManagerService);

	isLoadingGroups = signal<boolean>(false);
	shouldDisplayDashboard = this.#groupsStore.shouldDisplayDashboard;

	userSelectedGroup = computed(() => {
		const selectedGroupId = this.#userStore.selectedGroupId();
		return this.#groupsStore.groups()?.[selectedGroupId];
	});

	getListContent(userId: string) {
		this.isLoadingGroups.set(true);
		const user = this.#userStore.user();

		return this.#apiService
			.get<
				CompanyGroup[]
			>(`/access/listcontent/${userId}`, { params: { name: user.name, username: user.nickname } })
			.pipe(
				tap((data) => {
					const sortedData = data.sort((a, b) =>
						a.id.toString().toLowerCase().localeCompare(b.id.toString().toLowerCase()),
					);
					let selectedGroupId = this.#localStorageManager.readWithParse(STORAGE_SELECTED_GROUP_KEY);
					const existsSelectedGroupId = sortedData.find(
						(group) => group.access === selectedGroupId,
					);

					if (!selectedGroupId || !existsSelectedGroupId) {
						selectedGroupId = sortedData[0].access;
					}

					this.#userStore.setSelectedGroupId(selectedGroupId);
					sortedData.forEach((group) => this.#groupsStore.addGroup(group));
				}),
				delay(100),
				finalize(() => this.isLoadingGroups.set(false)),
			);
	}
}
