<div class="device-internet">
	<form [formGroup]="form">
		<csd-app-step text="VPN" icon="vpn_key">
			@if (deviceIp()) {
				<ng-container additional-content>
					<csd-app-inline-message variation="info-dark" classList="ml-5">
						{{ deviceIp() }}
					</csd-app-inline-message>
				</ng-container>
			}
			<mat-slide-toggle
				class="mat-slide-toggle-small"
				formControlName="vpn"
				hideIcon
				(change)="onChangeFormValue()"
			/>
		</csd-app-step>

		@if (form.controls.vpn.value) {
			<csd-app-step text="Filter" icon="filter_alt">
				<mat-select
					formControlName="vpnProxy"
					class="filter-selection select-text-right"
					placeholder="Select one option"
					[compareWith]="selectCompareValue"
					(selectionChange)="onChangeFormValue()"
					hideSingleSelectionIndicator
				>
					@for (item of filterOptions; track $index + item.value) {
						<mat-option class="option-small" [value]="item.value">{{ item.label }}</mat-option>
					}
				</mat-select>
			</csd-app-step>
		} @else {
			<csd-app-step text="Browser URL" icon="target" [contentGrow]="true">
				<div class="text-right">
					<mat-form-field
						appearance="outline"
						subscriptSizing="dynamic"
						class="csd-field csd-field--plain"
					>
						<input
							class="form-input"
							formControlName="browserUrl"
							placeholder="Browser Proxy"
							matInput
							(focusout)="onChangeFormValue()"
						/>
						<button
							matTextSuffix
							class="csd-field__button"
							(click)="openExceptionsModal($event, 'browser')"
						>
							Exceptions
						</button>
					</mat-form-field>
				</div>
			</csd-app-step>

			<csd-app-step text="Proxy Pac URL" icon="pest_control" [contentGrow]="true">
				<div class="text-right">
					<mat-form-field
						appearance="outline"
						subscriptSizing="dynamic"
						class="csd-field csd-field--plain"
					>
						<input
							class="form-input"
							[class.form-input--error]="fieldHasErrors('proxyPacUrl')"
							formControlName="proxyPacUrl"
							placeholder="Proxy Pac Url"
							matInput
							(focusout)="onChangeFormValue()"
						/>
						<button
							matTextSuffix
							class="csd-field__button"
							(click)="openExceptionsModal($event, 'proxy')"
						>
							Exceptions
						</button>
					</mat-form-field>
				</div>
				@if (fieldHasErrors('proxyPacUrl')) {
					<p @openClose class="text-right text-xs text-error-tertiary">
						Value provided is not valid
					</p>
				}
			</csd-app-step>
		}

		<csd-app-step text="Internet" icon="language"></csd-app-step>
	</form>
</div>
