<mat-expansion-panel class="user-area" #expansionPanel="matExpansionPanel">
	<mat-expansion-panel-header class="user-area__header">
		<mat-panel-title>
			<csd-app-user-image classList="mr-2 w-10 h-10" [user]="userName()"></csd-app-user-image>
			<div class="font-bold max-w-40">
				<p class="text-lg text-slate-700 truncate">{{ userName() }}</p>
			</div>
		</mat-panel-title>
	</mat-expansion-panel-header>
	<div class="user-area__body">
		<div class="flex flex-col gap-2">
			@for (group of groupList(); track group.id; let isFirst = $first) {
				<csd-app-button-group
					[classList]="getGroupButtonClasses(group.access, isFirst)"
					(clicked)="updateSelectedGroup(group.access)"
					(clickedAfter)="openConfig(group.access, group.status === groupUserRoles.ADMIN)"
				>
					{{ getGroupName(group) }}
					@if (group.status === groupUserRoles.ADMIN) {
						<ng-container button-group-after>
							<mat-icon
								class="material-symbols-filled align-middle leading-none hover:rotate-45 transition-all"
								>settings</mat-icon
							>
						</ng-container>
					}
				</csd-app-button-group>
			}

			@if (shouldDisplayConnect()) {
				<a routerLink="/onboarding"
					><csd-app-button classList="w-full" variation="secondary"
						>Connect Your Account</csd-app-button
					></a
				>
			}

			<csd-app-button classList="text-left w-full" icon="logout" type="text" (click)="logout()"
				>Log Out</csd-app-button
			>
		</div>
		<hr class="separator" [class.pb-5]="!groupList().length" />
	</div>
</mat-expansion-panel>
