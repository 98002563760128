<div class="grow transition-all duration-300 overflow-hidden h-16 flex items-center w-full">
	<csd-app-customer-info
		class="overflow-hidden transition-all duration-300"
		[class.w-full]="!isSearchOpen()"
		[class.w-0]="isSearchOpen()"
	></csd-app-customer-info>
	<csd-app-customer-search
		class="overflow-hidden transition-all duration-300"
		[class.w-full]="isSearchOpen()"
		[class.w-0]="!isSearchOpen()"
		(focusout)="searchFocusOut($event)"
		(optionSelected)="shouldOpenSearch.set(false)"
	></csd-app-customer-search>
</div>
