import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CompanyGroup } from '@feature/groups/groups.types';
import { TRY_AGAIN_TEXT } from '@root/app/app.constants';
import { catchError, throwError } from 'rxjs';

import { UsersLinkService } from '../users-link.service';

@Component({
	selector: 'csd-app-dashboard',
	standalone: true,
	imports: [MatSlideToggleModule],
	templateUrl: './dashboard.component.html',
	styleUrl: './dashboard.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
	#usersLinkService = inject(UsersLinkService);

	group = model.required<CompanyGroup>();
	userId = input.required<string>();

	onToggleChange(value: boolean) {
		const oldDashboard = this.group().dashboard;
		const data = {
			status: this.group().status,
			dashboard: value,
			userSub: this.userId(),
		};

		this.group.update((oldGroup) => ({ ...oldGroup, dashboard: value }));

		this.#usersLinkService
			.updateGroup(this.group().access, data)
			.pipe(
				catchError((err) => {
					this.group.update((oldGroup) => ({ ...oldGroup, dashboard: oldDashboard }));
					const msg = `Unable to update group dashboard at this time. ${TRY_AGAIN_TEXT}`;

					return throwError(() => new Error(err?.error?.message || msg));
				}),
			)
			.subscribe();
	}
}
