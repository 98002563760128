import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { BannerComponent } from '@ui/banner/banner.component';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, finalize, Subscription } from 'rxjs';

import { DevicesStore } from '../../devices/devices.store';
import { DeviceDetailsService } from '../device-details.service';

@Component({
	selector: 'csd-app-timer-banner',
	standalone: true,
	imports: [BannerComponent, ButtonComponent, DatePipe, LoadingIconComponent],
	templateUrl: './timer-banner.component.html',
	styleUrl: './timer-banner.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerBannerComponent implements OnInit, OnDestroy {
	readonly #devicesStore = inject(DevicesStore);
	#deviceDetailsService = inject(DeviceDetailsService);
	#notificationService = inject(NotificationService);

	device = this.#devicesStore.selectedDevice;
	isLoading = signal<boolean>(false);
	timerValidationInterval: number | null = null;
	timerValidationSeconds = 30 * 1000;
	subscriptions: Subscription[] = [];

	ngOnInit(): void {
		this.startTimerValidation();
	}

	ngOnDestroy(): void {
		this.removeTimerValidation();
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	closeDevice() {
		this.isLoading.set(true);
		const closeSub$ = this.#deviceDetailsService
			.removeTimer(this.device().id)
			.pipe(
				finalize(() => this.isLoading.set(false)),
				catchError(() => {
					throw new Error(
						`<p>Unable to remove temporary timer.</p>
            <p>Please check your device settings and try again.</p>`,
					);
				}),
			)
			.subscribe(this.handleRemoveTimerSuccess.bind(this));

		this.subscriptions.push(closeSub$);
	}

	handleRemoveTimerSuccess() {
		this.#notificationService.openSuccess({
			message: `Removed temporary timer for <strong>${this.device().name}</strong>.`,
		});

		this.#devicesStore.updateDeviceDetail(this.device().id, { overrideFinishedUtc: undefined });
		this.removeTimerValidation();
	}

	removeTimerValidation() {
		if (this.timerValidationInterval) {
			clearInterval(this.timerValidationInterval);
			this.timerValidationInterval = null;
		}
	}

	startTimerValidation() {
		this.removeTimerValidation();

		this.timerValidationInterval = window.setInterval(() => {
			const deviceTimerDate = new Date(this.device().overrideFinishedUtc as string);
			const deviceTimerSeconds = deviceTimerDate.getTime() / 1000;
			const currentDate = new Date();
			const currentDateSeconds = currentDate.getTime() / 1000;

			if (currentDateSeconds > deviceTimerSeconds) {
				// Retrive device to update all policies
				const sub$ = this.#deviceDetailsService.getDevice(this.device().id).subscribe();
				this.subscriptions.push(sub$);
			}
		}, this.timerValidationSeconds);
	}
}
