import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnDestroy,
	signal,
	ViewChild,
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { finalize, Subscription } from 'rxjs';

import { UsersService } from './users.service';
import { UsersStore } from './users.store';
import { AuthentikUser } from './users.types';

@Component({
	selector: 'csd-app-users',
	standalone: true,
	imports: [LoaderComponent, MatTableModule, MatPaginatorModule, InputSearchComponent],
	providers: [UsersService],
	templateUrl: './users.component.html',
	styleUrl: './users.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent implements AfterViewInit, OnDestroy {
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	#usersService = inject(UsersService);
	#usersStore = inject(UsersStore);
	#router = inject(Router);

	displayedColumns = ['name', 'username', 'email'];
	loadingUsers = signal<boolean>(false);
	users = this.#usersStore.usersList;
	filteredUsers = signal<AuthentikUser[]>([]);
	subscriptions$: Subscription[] = [];

	dataSource = computed<MatTableDataSource<AuthentikUser, MatPaginator>>(() => {
		const matTableData = new MatTableDataSource(this.filteredUsers());
		matTableData.paginator = this.paginator;
		return matTableData;
	});

	ngAfterViewInit(): void {
		if (this.users().length) {
			this.filteredUsers.set(this.users());
			return;
		}

		this.loadUsers();
	}

	ngOnDestroy(): void {
		this.subscriptions$.forEach((sub) => sub?.unsubscribe());
	}

	filterUsers(value: string) {
		const searchValue = (value || '').toLowerCase();
		const filter = this.users().filter((user) => {
			const name = user.name.toLowerCase();
			const username = user.userName.toLowerCase();
			return name.includes(searchValue) || username.includes(searchValue);
		});
		this.filteredUsers.set(filter);
	}

	loadUsers() {
		this.loadingUsers.set(true);

		const sub$ = this.#usersService
			.getAuthentikUsers()
			.pipe(finalize(() => this.loadingUsers.set(false)))
			.subscribe((data) => {
				this.filteredUsers.set(data);
			});

		this.subscriptions$.push(sub$);
	}

	setUser(user: AuthentikUser) {
		this.#usersStore.setSelectedUser(user.userName);
		this.#router.navigate(['/configuration/users', user.userName]);
	}
}
