export type GroupsState = {
	groups: Record<string, CompanyGroup>;
	selectedGroupId: string;
};

export interface CompanyGroup {
	id: number;
	user: string;
	groupName: string;
	access: string;
	dashboard: boolean;
	isGroup: boolean;
	isEmypeople: boolean;
	status: CompanyGroupUserRole;
	connectAccountButton: boolean;
	adminAccess: boolean;
	appsAccess: boolean;
	itemGroupAccess: boolean;
	usersList?: UserGroup[];
}

export enum CompanyGroupUserRole {
	ADMIN,
	USER,
}

export type CompanyGroupBase = {
	access: string;
	name: string;
	isGroup: boolean;
};

export type UserGroupBase = {
	sub: string;
	name: string;
	userName: string;
};

export type UserGroup = {
	dashboard: boolean;
	status: number;
	user: UserGroupBase;
};
