import { inject, Injectable } from '@angular/core';
import { ApiService } from '@root/app/core/api/api.service';
import { AppOptions } from '@root/app/shared/apps.types';
import { startWith, tap } from 'rxjs';

import { ConfigStore } from './config.store';

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	readonly #configStore = inject(ConfigStore);
	#apiService = inject(ApiService);

	getAppOptions() {
		return this.#apiService.get<AppOptions>('/android/apps/appoptions').pipe(
			startWith({
				appStatuses: {},
				appTypes: {},
				vpnOptions: {},
				vpnBlockOptions: {},
				vpnDirectOptions: {},
			} as AppOptions),
			tap((data) => {
				this.#configStore.addAppOptions(data);
			}),
		);
	}
}
