import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	OnDestroy,
	signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';
import { AppOptions } from '@root/app/shared/apps.types';
import { AppItemComponent } from '@ui/app-item/app-item.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, finalize, Subscription } from 'rxjs';

import { ConfigStore } from '../../config/config.store';
import { DeviceDetailsService } from '../../device-details/device-details.service';
import { DevicesStore } from '../../devices/devices.store';
import { Policy } from '../../policy-details/policy-details.types';
import { AppsService, DeviceAppsUpdate } from './apps.service';

@Component({
	selector: 'csd-app-apps',
	standalone: true,
	imports: [
		AppItemComponent,
		EmptyStateComponent,
		FormsModule,
		HeaderDividerComponent,
		LoaderComponent,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
	],
	templateUrl: './apps.component.html',
	styleUrl: './apps.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsComponent implements OnDestroy {
	readonly #configStore = inject(ConfigStore);
	readonly #devicesStore = inject(DevicesStore);
	#appsService = inject(AppsService);
	#deviceDetailsService = inject(DeviceDetailsService);
	#notificationService = inject(NotificationService);

	appOptions = this.#configStore.appOptions;
	device = this.#devicesStore.selectedDevice;
	isLoading = this.#deviceDetailsService.isLoading;
	isResetingConfig = signal<boolean>(false);
	launcherFilterApps = signal<AndroidAppConfig[]>([]);
	systemFilterApps = signal<AndroidAppConfig[]>([]);

	searchText = '';
	subscriptions: Subscription[] = [];

	devicePolicyApps = computed(() => {
		return this.device()?.policy?.policyAppJoins || [];
	});
	launcherApps = computed(() => {
		const { appTypes = {} } = this.appOptions() as AppOptions;
		const normalAppsId = Object.values(appTypes).findIndex((el) => el === 'Launcher');

		return this.devicePolicyApps().filter((el) => el?.appResponse?.type === normalAppsId);
	});
	systemApps = computed(() => {
		const { appTypes = {} } = this.appOptions() as AppOptions;
		const systemAppsId = Object.values(appTypes).findIndex((el) => el === 'System');

		return this.devicePolicyApps().filter((el) => el?.appResponse?.type === systemAppsId);
	});

	constructor() {
		effect(this.updateComponentData.bind(this), { allowSignalWrites: true });
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	clearSearch() {
		this.searchText = '';
		this.filterApps(this.searchText);
	}

	filterApps(value: string) {
		let launcherFilterApps = this.launcherApps();
		let systemFilterApps = this.systemApps();

		if (value) {
			launcherFilterApps = this.launcherApps().filter((item) => this.filterPackage(item, value));
			systemFilterApps = this.systemApps().filter((item) => this.filterPackage(item, value));
		}

		this.launcherFilterApps.set(launcherFilterApps);
		this.systemFilterApps.set(systemFilterApps);
	}

	filterPackage(item: AndroidAppConfig, value: string) {
		const matchRegex = new RegExp(value, 'gi');
		const { appResponse } = item;

		return appResponse?.name?.match(matchRegex) || appResponse?.packageName?.match(matchRegex);
	}

	notifyUpdateStarted(message: string) {
		this.#notificationService.open({ message });
	}

	notifyUpdateSuccess(message: string) {
		this.#notificationService.openSuccess({ message });
	}

	updateComponentData() {
		if (!this.device()) {
			return;
		}

		this.filterApps(this.searchText);
	}

	updateStatus(newAppData: DeviceAppsUpdate, app: AndroidAppConfig) {
		const appName = app.appResponse.name;
		this.notifyUpdateStarted(`Updating <strong>${appName}</strong>`);

		const updateSub$ = this.#appsService
			.updateStatus(this.device()?.id as string, newAppData)
			.pipe(
				catchError((err) => {
					throw new Error(err.error?.title || err.message);
				}),
			)
			.subscribe((response) => {
				const { appId, internetConfig, status, differentThanGroup } = response;
				const dataToUpdateLocally = { status, internetConfig, differentThanGroup };
				this.updateAppInStore(appId, dataToUpdateLocally);
				this.notifyUpdateSuccess(`App policy for <strong>${appName}</strong> was updated`);
			});

		this.subscriptions.push(updateSub$);
	}

	resetAppConfig(app: AndroidAppConfig) {
		const deviceId = this.device()?.id as string;
		const appName = app.appResponse.name;
		this.notifyUpdateStarted(`Resetting config for <strong>${appName}</strong>...`);

		const sub$ = this.#appsService
			.removeAppValues(deviceId, app.appId)
			.pipe(finalize(() => this.isResetingConfig.set(false)))
			.subscribe((response) => {
				const { internetConfig, status, differentThanGroup } = response || {};
				const dataToUpdateLocally = { status, internetConfig, differentThanGroup };

				this.updateAppInStore(app.appId, dataToUpdateLocally);
				this.notifyUpdateSuccess(`Config for app ${appName} was reset successfully`);
			});

		this.subscriptions.push(sub$);
	}

	updateAppInStore(appId: string, dataToUpdateLocally: Partial<AndroidAppConfig>) {
		const newAppsList = this.devicePolicyApps().map((app) => {
			if (app.appId === appId) {
				return { ...app, ...dataToUpdateLocally };
			}

			return app;
		});
		this.#devicesStore.updateDeviceDetail(this.device()?.id as string, {
			policy: { ...((this.device()?.policy || {}) as Policy), policyAppJoins: newAppsList },
		});
	}
}
