import { ErrorHandler, inject } from '@angular/core';
import { NotificationService } from '@ui/notification/notification.service';

export class MyErrorHandler implements ErrorHandler {
	notificationService = inject(NotificationService);

	handleError(error: Error | any) {
		let message = error?.error?.message || error?.message;

		if (error.status === 404) {
			message = '<strong>Resource not found</strong>. Please refresh the page or try again later.';
		}

		this.notificationService.openError({ message });

		console.error(error);
	}
}
