<csd-app-loader [isLoading]="isLoadingData()">
	<div class="h-full flex flex-col text-sm">
		<div class="grow flex flex-col gap-3">
			@for (note of notes(); track note.id; let i = $index) {
				<csd-app-note-item [note]="note" />
			}
		</div>
		<div class="comment-form" [formGroup]="form">
			<textarea
				class="comment-form__textarea"
				formControlName="comment"
				placeholder="Comment"
			></textarea>
			<mat-checkbox
				class="comment-form__checkbox mat-mdc-checkbox__space-between"
				formControlName="pinned"
				labelPosition="before"
				>Pinned note?</mat-checkbox
			>
			<button
				class="comment-form__button"
				matRipple
				[disabled]="isSaving()"
				(click)="onSubmitNote()"
			>
				Add Comment
				@if (isSaving()) {
					<csd-app-loading-icon />
				}
			</button>
		</div>
	</div>
</csd-app-loader>
