import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	effect,
	inject,
	ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderComponent } from '@ui/loader/loader.component';

import { DevicesStore } from '../devices/devices.store';
import { PoliciesStore } from '../policies/policies.store';
import { UserStore } from '../user/user.store';
import { CustomersService } from './customers.service';
import { CustomersStore } from './customers.store';
import { CustomersFromList } from './customers.types';

@Component({
	selector: 'csd-app-customers',
	standalone: true,
	templateUrl: './customers.component.html',
	styleUrl: './customers.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LoaderComponent, MatIconModule, MatPaginatorModule, MatTableModule],
})
export class CustomersComponent {
	@ViewChild(MatPaginator) paginator!: MatPaginator;

	readonly #customersStore = inject(CustomersStore);
	readonly #devicesStore = inject(DevicesStore);
	readonly #policiesStore = inject(PoliciesStore);
	readonly #userStore = inject(UserStore);
	#customersService = inject(CustomersService);
	#ref = inject(ChangeDetectorRef);
	#router = inject(Router);

	displayedColumns = ['code', 'name', 'email', 'phone'];
	isLoading = this.#customersService.isLoading;
	data = this.#customersService.getAllCustomers(this.#userStore.selectedGroupId());

	dataSource = computed(() => {
		return new MatTableDataSource(this.data() as unknown as CustomersFromList[]);
	});

	constructor() {
		effect(() => {
			if (this.dataSource()?.data?.length) {
				this.updatePaginator();
			}
		});
	}

	setCustomer(customer: CustomersFromList) {
		this.#customersStore.resetState();
		this.#devicesStore.resetState();
		this.#policiesStore.resetState();
		this.#customersStore.updateSelectedCustomer(customer.customerId);
		this.#router.navigate(['customer', customer.customerId, 'overview']);
	}

	updatePaginator() {
		this.#ref.detectChanges();
		this.dataSource().paginator = this.paginator;
	}
}
