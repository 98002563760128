import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationStart, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { CustomerSelectionComponent } from '@feature/customer-selection/customer-selection.component';
import { CustomersStore } from '@feature/customers/customers.store';
import { HistoryComponent } from '@feature/history/history.component';
import { UserService } from '@feature/user/user.service';
import { triggerFadeInOut } from '@root/app/animations';
import { filterNavigationTransition } from '@root/app/utils/filter-navigation-transition';
import { BreadcrumbComponent } from '@ui/breadcrumb/breadcrumb.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { filter, map } from 'rxjs';

import { SidebarComponent } from './sidebar/sidebar.component';

@Component({
	selector: 'csd-app-main-layout',
	standalone: true,
	templateUrl: './main-layout.component.html',
	styleUrl: './main-layout.component.scss',
	imports: [
		BreadcrumbComponent,
		CustomerSelectionComponent,
		HistoryComponent,
		LoaderComponent,
		MatIconModule,
		MatSidenavModule,
		RouterOutlet,
		SidebarComponent,
		EmptyStateComponent,
	],
	animations: [triggerFadeInOut],
})
export class MainLayoutComponent {
	readonly #store = inject(CustomersStore);
	#breakpointObserver = inject(BreakpointObserver);
	#userService = inject(UserService);
	#router = inject(Router);

	customer = this.#store.selectedCustomer;
	isLoadingUserGroups = this.#userService.isLoadingGroups;
	isMobile = signal<boolean>(false);
	shouldDisplayDashboard = this.#userService.shouldDisplayDashboard;
	shouldOpenSidenav = signal<boolean>(true);

	isLoadingRoute = toSignal(
		this.#router.events.pipe(
			filter(filterNavigationTransition),
			map((e) => {
				const sectionUrl = (e as RouterEvent).url.replace(/(^\/|\/$)/gi, '').split('/');
				const innerSectionLevel = 4;
				const isInnerSection = sectionUrl.length >= innerSectionLevel;

				return !isInnerSection && e instanceof NavigationStart;
			}),
		),
	);

	constructor() {
		this.startBreakpointObserver();
	}

	startBreakpointObserver() {
		this.#breakpointObserver.observe(['(max-width: 1024px)']).subscribe((screenSize) => {
			this.shouldOpenSidenav.set(!screenSize.matches);
			this.isMobile.set(screenSize.matches);
		});
	}

	openSideNav() {
		this.shouldOpenSidenav.set(true);
	}

	onChangeSidenav(isOpen: boolean) {
		this.shouldOpenSidenav.set(isOpen);
	}
}
