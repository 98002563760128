<div class="customers-comp">
	<csd-app-loader [isLoading]="isFirstTimeLoading()">
		<table mat-table [dataSource]="dataSource()" class="mdc-data-table--with-link">
			<!-- Code Column -->
			<ng-container matColumnDef="code">
				<th mat-header-cell *matHeaderCellDef width="20%">Code</th>
				<td mat-cell *matCellDef="let element" width="20%">{{ element.customerId }}</td>
			</ng-container>

			<!-- Name Column -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef width="30%">Name</th>
				<td mat-cell *matCellDef="let element" width="30%">{{ element.name }}</td>
			</ng-container>

			<!-- Email Column -->
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef width="20%">Email</th>
				<td mat-cell *matCellDef="let element" width="20%">{{ element.email }}</td>
			</ng-container>

			<!-- Phone Column -->
			<ng-container matColumnDef="phone">
				<th mat-header-cell *matHeaderCellDef width="20%">Phone</th>
				<td mat-cell *matCellDef="let element" width="20%">{{ element.officePhone }}</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="setCustomer(row)"></tr>
		</table>

		<div class="table-pagination-wrapper">
			@if (isLoadingCustomers()) {
				<div class="pagination-loader"><csd-app-loading-icon /> Loading more customers</div>
			}
			<mat-paginator
				[pageSizeOptions]="[10]"
				[showFirstLastButtons]="false"
				[pageSize]="10"
				[disabled]="isLoadingCustomers()"
				hidePageSize
				aria-label="Select page of customers"
			>
			</mat-paginator>
		</div>
	</csd-app-loader>
</div>
