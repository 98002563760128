import { DeviceNewAppPolicy } from '@feature/policies/policies.service';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';

export const formatAndroidApps = (data: AndroidAppConfig[]) =>
	data.map(
		(app): DeviceNewAppPolicy => ({
			appId: app.appResponse.id,
			packageName: app.appResponse.packageName,
			status: Number(app.status),
			internetConfig: Number(app.internetConfig),
		}),
	);
