<div
	class="pinned-notes"
	[ngClass]="classList()"
	(mouseenter)="stopScrolling()"
	(mouseleave)="startScrolling()"
>
	<mat-icon inline class="material-symbols-filled pinned-notes__icon">text_snippet</mat-icon>
	<div class="pinned-notes__list">
		@if (noteOne() && backwards()) {
			<p class="truncate" [innerHTML]="noteOne()" @rotateNotesBack></p>
		}

		@if (noteOne() && !backwards()) {
			<p class="truncate" [innerHTML]="noteOne()" @rotateNotes></p>
		}

		@if (noteTwo() && backwards()) {
			<p class="truncate" [innerHTML]="noteTwo()" @rotateNotesBack></p>
		}

		@if (noteTwo() && !backwards()) {
			<p class="truncate" [innerHTML]="noteTwo()" @rotateNotes></p>
		}
	</div>
	<div class="pinned-notes__controls">
		<button (click)="changeNote(true)" matRipple>
			<mat-icon inline class="leading-0 align-middle">chevron_left</mat-icon>
		</button>
		<button (click)="changeNote()" matRipple>
			<mat-icon inline class="leading-0 align-middle">chevron_right</mat-icon>
		</button>
	</div>
</div>
