import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	effect,
	inject,
	OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { DeviceSubscriptionData } from '@ui/modal/device-subscription/device-subscription.component';
import { ModalService } from '@ui/modal/modal.service';
import { selectCompareValue } from '@utils/select-compare-value';

import { DeviceDetailsService } from '../../device-details/device-details.service';
import { DevicesStore } from '../../devices/devices.store';
import { PoliciesStore } from '../../policies/policies.store';
import { GroupBase } from '../../policies/policies.types';
import { CommandsComponent } from './commands/commands.component';
import { HeaderComponent } from './header/header.component';
import { PolicyComponent } from './policy/policy.component';
import { StatsDataService } from './stats-data.service';
import { FIELD_FRIENDLY_NAMES, STATUS_ITEMS, UPDATES_CHANNELS } from './stats.constants';
import { StatsService } from './stats.service';
import { UninstallKeyComponent } from './uninstall-key/uninstall-key.component';

@Component({
	selector: 'csd-app-stats',
	standalone: true,
	imports: [
		CommandsComponent,
		DatePipe,
		HeaderComponent,
		HeaderDividerComponent,
		MatIconModule,
		MatSelectModule,
		PolicyComponent,
		ReactiveFormsModule,
		UninstallKeyComponent,
	],
	providers: [StatsDataService],
	templateUrl: './stats.component.html',
	styleUrl: './stats.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsComponent implements OnInit {
	readonly #devicesStore = inject(DevicesStore);
	readonly #policiesStore = inject(PoliciesStore);
	#destroyRef = inject(DestroyRef);
	#deviceDetailsService = inject(DeviceDetailsService);
	#fb = inject(FormBuilder);
	#modalService = inject(ModalService);
	#statsDataService = inject(StatsDataService);
	#statsService = inject(StatsService);

	device = this.#devicesStore.selectedDevice;
	selectedWorkOrder = this.#devicesStore.selectedSubscription;

	form = this.#fb.group({
		name: this.device().name,
		releaseChannel: this.device().releaseChannel,
		group: this.device().group?.id || '',
		uninstallKey: this.device().uninstallKey || '',
	});
	groups = this.#policiesStore.allGroups;
	selectCompareValueFn = selectCompareValue;
	statusItems = STATUS_ITEMS;
	updatesChannels = UPDATES_CHANNELS.filter((channel) =>
		this.#statsDataService.isEnvEnable(channel.env),
	);
	qrCodeUrl: string = '';

	constructor() {
		effect(() => {
			const groupLength = this.groups().length;

			if (groupLength && groupLength > 1) {
				this.form.get('group')?.enable();
			} else {
				this.form.get('group')?.disable();
			}
		});
	}

	ngOnInit(): void {
		this.qrCodeUrl = this.#deviceDetailsService.getQrCode(this.device().id);

		const subscriptionGroup = this.#devicesStore.getSubscription(this.device().workOrder);
		const deviceExistsInGroup = subscriptionGroup.secureLockAndroid.find(
			(device) => device.id === this.device().id,
		);

		if (!deviceExistsInGroup) {
			this.#devicesStore.addDeviceToSubscription(this.device().workOrder, this.device());
		}
	}

	loadDevice() {
		this.#deviceDetailsService
			.getDevice(this.device().id)
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe();
	}

	onNameChanged(value: string) {
		this.form.get('name')?.setValue(value);
		this.updateDevice('name');
	}

	async openWorkOrder() {
		const data: DeviceSubscriptionData = {
			id: this.device().id,
			...this.#statsDataService.getDeviceBaseData(this.device()),
		};
		await this.#modalService.open('device-subscription', data);
	}

	updateDevice(field: string) {
		const fieldValue = this.form.get(field)?.value;
		const fieldName = FIELD_FRIENDLY_NAMES[field];
		const data = this.#statsDataService.getDataToUpdate(field, fieldValue, this.device());

		if (!data) {
			return;
		}

		const deviceId = this.device().id;
		const groups = this.groups() as GroupBase[];

		this.#statsService.updateDevice(deviceId, data, groups, fieldName).subscribe(() => {
			if (field === 'group') {
				this.loadDevice();
			}
		});
	}
}
