<div class="content-apps" [class.content-apps--empty]="!appsList().length">
	@for (item of appsList(); track item.appId) {
		@defer (on viewport) {
			<csd-app-app-item
				class="app-item"
				[data]="item"
				[appsStatus]="getAppStatusOptions(item)"
				[vpnOptions]="getVpnOptions(item)"
				[vpnOn]="vpnOn()"
				[hasLimitedOptions]="
					item.leastRestrictiveAppStatusAllowed > 0 ||
					item.leastRestrictiveInternetConfigAllowed > 0
				"
				(changedConfig)="updateStatus($event, item)"
				(reset)="resetAppConfig(item)"
			/>
		} @placeholder (minimum 250ms) {
			<div class="app-item app-item--placeholder"></div>
		}
	} @empty {
		<csd-app-empty-state [onlyText]="true">
			<ng-content empty-state-message>
				<div class="flex flex-col items-center">
					<mat-icon>{{ emptyMessage().icon }}</mat-icon>
					{{ emptyMessage().message }}
				</div>
			</ng-content>
		</csd-app-empty-state>
	}
</div>
