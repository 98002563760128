<div class="device-apps">
	@if (devicePolicyApps().length) {
		<div class="search-apps">
			<csd-app-input-search
				(changed)="filterApps($event)"
				[search]="searchText"
				placeholder="Search apps"
			/>
		</div>
	}

	<csd-app-loader [isLoading]="isLoading()">
		@if (launcherApps().length) {
			<csd-app-header-divider text="Launcher Apps" />
			<div class="content-apps" [class.content-apps--empty]="!launcherFilterApps().length">
				@for (item of launcherFilterApps(); track item.appId) {
					@defer (on viewport) {
						<csd-app-app-item
							class="app-item"
							[data]="item"
							[appsStatus]="getAppStatusOptions(item)"
							[vpnOptions]="getVpnOptions(item)"
							[vpnOn]="!!device().vpnOn"
							(changedConfig)="updateStatus($event, item)"
							(reset)="resetAppConfig(item)"
						></csd-app-app-item>
					} @placeholder (minimum 250ms) {
						<div class="app-item app-item--placeholder"></div>
					}
				}

				@if (!launcherFilterApps().length) {
					<csd-app-empty-state [onlyText]="true">
						<ng-content empty-state-message>
							<div class="flex flex-col items-center">
								<mat-icon>rocket_launch</mat-icon>
								No launcher apps found matching your search.
							</div>
						</ng-content>
					</csd-app-empty-state>
				}
			</div>
		}

		@if (systemApps().length) {
			<csd-app-header-divider text="System Apps" />
			<div class="content-apps" [class.content-apps--empty]="!systemFilterApps().length">
				@for (item of systemFilterApps(); track item.appId) {
					@defer (on viewport) {
						<csd-app-app-item
							class="app-item"
							[data]="item"
							[appsStatus]="getAppStatusOptions(item)"
							[vpnOptions]="getVpnOptions(item)"
							[vpnOn]="!!device().vpnOn"
							(changedConfig)="updateStatus($event, item)"
							(reset)="resetAppConfig(item)"
						></csd-app-app-item>
					} @placeholder (minimum 250ms) {
						<div class="app-item app-item--placeholder"></div>
					}
				}

				@if (!systemFilterApps().length) {
					<csd-app-empty-state [onlyText]="true">
						<ng-content empty-state-message>
							<div class="flex flex-col items-center">
								<mat-icon>apk_document</mat-icon>
								No system apps found matching your search.
							</div>
						</ng-content>
					</csd-app-empty-state>
				}
			</div>
		}

		@if (!devicePolicyApps().length) {
			<div class="py-10">
				<csd-app-empty-state icon="apps">
					No apps added to manage this device.
				</csd-app-empty-state>
			</div>
		}
	</csd-app-loader>
</div>
