import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationStart, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { filterNavigationTransition } from '@root/app/utils/filter-navigation-transition';
import { SectionMenuComponent, SectionMenuItem } from '@ui/section-menu/section-menu.component';
import { isNavigationEnd } from '@utils/routing/is-navigation-end';
import { filter, map, Subscription } from 'rxjs';

import { POLICY_LINKS } from './policy-layout.constants';

@Component({
	selector: 'csd-app-policy-layout',
	standalone: true,
	imports: [RouterOutlet, SectionMenuComponent],
	templateUrl: './policy-layout.component.html',
	styleUrl: './policy-layout.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyLayoutComponent implements OnInit, OnDestroy {
	#router = inject(Router);

	currentRoute = signal<string>('');
	subscriptions: Subscription[] = [];

	policyLinks = computed<SectionMenuItem[]>(() => {
		return POLICY_LINKS.map((item) => ({
			...item,
			active: item.route === this.currentRoute(),
		}));
	});

	isLoadingRoute = toSignal(
		this.#router.events.pipe(
			filter(filterNavigationTransition),
			map((e) => {
				const sectionUrl = (e as RouterEvent).url.replace(/(^\/|\/$)/gi, '').split('/');
				const innerPolicySectionLevel = 4;
				const isPolicyDetail = sectionUrl.length >= innerPolicySectionLevel;

				return isPolicyDetail && e instanceof NavigationStart;
			}),
		),
	);

	ngOnInit() {
		this.setCurrentRouteFromUrl();
		this.startRouterListening();
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	startRouterListening() {
		const router$ = this.#router.events
			.pipe(isNavigationEnd())
			.subscribe(this.setCurrentRouteFromUrl.bind(this));
		this.subscriptions.push(router$);
	}

	setCurrentRouteFromUrl() {
		const urlTree = this.#router.parseUrl(this.#router.url);
		const urlSegments = urlTree?.root?.children['primary']?.segments || [];

		this.currentRoute.set(urlSegments[urlSegments.length - 1]?.path);
	}
}
