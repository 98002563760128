import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { forkJoin, map, Observable, of, tap } from 'rxjs';

import { GroupsStore } from '../groups/groups.store';
import { CompanyGroup, UserGroup } from '../groups/groups.types';
import { UserService } from '../user/user.service';
import { UserStore } from '../user/user.store';
import { GroupDetailService } from './group-detail.service';

export const groupDetailResolver: ResolveFn<Observable<[CompanyGroup, UserGroup[]]>> = (route) => {
	const groupDetailService = inject(GroupDetailService);
	const userService = inject(UserService);
	const groupsStore = inject(GroupsStore);
	const userStore = inject(UserStore);
	const access = route.paramMap.get('groupId') as string;

	const currentGroup = groupsStore.getGroup(access);
	const groupRequest = currentGroup
		? of([currentGroup])
		: userService.getListContent(userStore.user().sub as string);
	const usersRequest = currentGroup?.usersList?.length
		? of(currentGroup.usersList)
		: groupDetailService.getGroupUsers(access);

	return forkJoin([groupRequest, usersRequest]).pipe(
		tap(() => {
			groupsStore.setSelectedGroupId(access);
		}),
		map((data) => {
			const [groups, users] = data;
			const currentGroup = groups.find((group) => group.access === access);

			if (!currentGroup) {
				throw new Error(
					'This group id is invalid or no longer accessible. Please verify the correct id or return to the groups page.',
				);
			}

			return [currentGroup, users];
		}),
	);
};
