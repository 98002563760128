import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
	ViewChild,
} from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { ConfigService } from '@feature/config/config.service';
import { PoliciesStore } from '@feature/policies/policies.store';
import { MainLayoutService } from '@layout/main-layout/main-layout.service';
import { ButtonGroupComponent } from '@ui/button-group/button-group.component';
import { ButtonComponent } from '@ui/button/button.component';
import { UserImageComponent } from '@ui/user-image/user-image.component';

import { GroupsStore } from '../groups/groups.store';
import { CompanyGroup, CompanyGroupUserRole } from '../groups/groups.types';
import { UserStore } from '../user/user.store';

@Component({
	selector: 'csd-app-user-area',
	standalone: true,
	templateUrl: './user-area.component.html',
	styleUrl: './user-area.component.scss',
	imports: [
		ButtonComponent,
		ButtonGroupComponent,
		MatExpansionModule,
		MatIconModule,
		RouterLink,
		UserImageComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAreaComponent implements AfterViewInit {
	@ViewChild(MatExpansionPanel) expansionPanel!: MatExpansionPanel;

	readonly #groupsStore = inject(GroupsStore);
	readonly #policiesStore = inject(PoliciesStore);
	readonly #userStore = inject(UserStore);
	#authService = inject(AuthService);
	#configService = inject(ConfigService);
	#mainLayoutService = inject(MainLayoutService);
	#router = inject(Router);

	groupUserRoles = CompanyGroupUserRole;
	isMobile = input<boolean>(false);
	shouldDisplayConnect = this.#groupsStore.shouldDisplayConnect;

	groupList = computed(() => {
		return this.#groupsStore.groupsList().filter((group) => group.dashboard);
	});
	userName = computed(() => this.#userStore.user()?.name ?? '');

	ngAfterViewInit(): void {
		this.expansionPanel._body.nativeElement.classList.add('user-area__content');
	}

	getGroupName(group: CompanyGroup) {
		return group.groupName || group.access;
	}

	getGroupButtonClasses(groupId: string, isFirst: boolean) {
		return [
			'font-bold',
			'hover:bg-slate-300',
			this.isActiveGroup(groupId, isFirst) ? 'bg-layout-cadet-gray' : 'bg-layout-sky',
		];
	}

	isActiveGroup(groupId: string, isFirst: boolean) {
		const selectedGroup = this.#userStore.selectedGroupId();

		return selectedGroup ? selectedGroup === groupId : isFirst;
	}

	logout() {
		this.#authService.logout().subscribe(() => {
			this.#router.navigate(['authentication', 'login']);
		});
	}

	openConfig(groupId: string, isAdmin = false) {
		if (!isAdmin) {
			return;
		}

		this.expansionPanel?.close();
		this.#router.navigate(['group', groupId]);

		if (this.isMobile()) {
			this.#mainLayoutService.toggleSidenav(false);
		}
	}

	updateSelectedGroup(groupId: string, resetGroups = false) {
		this.expansionPanel?.close();
		this.#userStore.setSelectedGroupId(groupId);
		this.validateConfigRoute();

		if (groupId && resetGroups) {
			this.#policiesStore.resetGroups();
		}
	}

	validateConfigRoute() {
		const isConfigRoute = this.#router.url.match(/^\/configuration/);

		if (!isConfigRoute) {
			return;
		}

		this.#configService.canAccessConfigurationRoute(this.#router.url);
	}
}
