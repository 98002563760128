import {
	ChangeDetectionStrategy,
	Component,
	computed,
	DestroyRef,
	inject,
	OnInit,
	output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DevicesStore } from '@feature/devices/devices.store';
import { MainLayoutService } from '@layout/main-layout/main-layout.service';
import { PinnedNotesComponent } from '@ui/pinned-notes/pinned-notes.component';

import { DeviceNameComponent } from '../device-name/device-name.component';
import { NotesComponent } from '../notes/notes.component';
import { NotesService } from '../notes/notes.service';
import { NotesStore } from '../notes/notes.store';

@Component({
	selector: 'csd-app-header',
	standalone: true,
	imports: [DeviceNameComponent, ReactiveFormsModule, MatIconModule, PinnedNotesComponent],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
	#destroyRef = inject(DestroyRef);
	#notesService = inject(NotesService);
	#notesStore = inject(NotesStore);
	#devicesStore = inject(DevicesStore);
	#mainLayoutService = inject(MainLayoutService);

	nameChanged = output<string>();

	notes = this.#notesStore.notesList;
	device = this.#devicesStore.selectedDevice;

	pinnedNotes = computed(() => this.#notesStore.pinnedNotes().map((note) => note.comment));
	deviceName = computed(() => this.device().name);

	ngOnInit(): void {
		this.loadNotes();
	}

	onChangeName(value: string) {
		this.nameChanged.emit(value);
	}

	loadNotes() {
		this.#notesService
			.loadNotes(this.device().id)
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe();
	}

	openNotes() {
		this.#mainLayoutService.openDrawer<NotesComponent>('Notes', NotesComponent);
	}
}
