import { AndroidDevice } from '../device-details.types';

export const UPDATES_CHANNELS = [
	{
		label: 'Stable',
		value: 2,
	},
	{
		label: 'Beta',
		value: 1,
	},
	{
		label: 'Alpha',
		value: 0,
	},
];

export const FIELD_FRIENDLY_NAMES: Record<string, string> = {
	name: 'Name',
	releaseChannel: 'Channel Update',
	group: 'Policy',
	uninstallKey: 'Unlock Code',
};

export const STATUS_ITEMS: { label: string; property: keyof AndroidDevice }[] = [
	{
		label: 'Manufacturer',
		property: 'manufacturer',
	},
	{
		label: 'Name',
		property: 'name',
	},
	{
		label: 'Model',
		property: 'model',
	},
	{
		label: 'Serial Number',
		property: 'serialNumber',
	},
	{
		label: 'IMEI',
		property: 'imeI1',
	},
	{
		label: 'Phone Number',
		property: 'imeI1Carrier',
	},
	{
		label: 'IMEI 2',
		property: 'imeI2',
	},
	{
		label: 'Phone Number 2',
		property: 'imeI2Carrier',
	},
];
