import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NotificationService } from '@ui/notification/notification.service';
import { finalize, Subscription } from 'rxjs';

import { GroupsStore } from '../../groups/groups.store';
import { CompanyGroupUpdate, GroupDetailService } from '../group-detail.service';

@Component({
	selector: 'csd-app-header',
	standalone: true,
	imports: [FormsModule, MatIconModule],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
	readonly #groupsStore = inject(GroupsStore);
	#groupDetailService = inject(GroupDetailService);
	#notificationService = inject(NotificationService);

	group = this.#groupsStore.selectedGroup;
	isEditing = signal<boolean>(false);
	isLoading = signal<boolean>(false);
	groupName = signal<string>('');
	hasGroupNameError = signal<boolean>(false);
	subscriptions = signal<Subscription[]>([]);

	ngOnInit(): void {
		this.groupName.set(this.group()?.groupName || '');
	}

	ngOnDestroy(): void {
		this.subscriptions().forEach((sub) => sub?.unsubscribe());
	}

	onCancel() {
		this.groupName.set(this.group()?.groupName || '');
		this.isEditing.set(false);
		this.hasGroupNameError.set(false);
	}

	onNameChange() {
		if (!this.groupName()) {
			this.hasGroupNameError.set(true);
			return;
		}

		const access = this.group()?.access as string;
		const updateData: CompanyGroupUpdate = {
			name: this.groupName(),
			isGroup: true,
		};

		this.updateGroup(access, updateData);
	}

	updateGroup(access: string, updateData: CompanyGroupUpdate) {
		this.isLoading.set(true);
		this.#notificationService.open({ message: 'Updating group name...' });
		const sub$ = this.#groupDetailService
			.updateGroupData(access, updateData)
			.pipe(finalize(() => this.isLoading.set(false)))
			.subscribe(this.updateGroupSuccess.bind(this));

		this.subscriptions().push(sub$);
	}

	updateGroupSuccess() {
		const access = this.group()?.access as string;
		this.#notificationService.openSuccess({ message: 'Successfully updated group name' });
		this.isEditing.set(false);
		this.#groupsStore.updateGroup(access, { groupName: this.groupName() });
	}
}
