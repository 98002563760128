import { DeviceNewAndroidPolicy } from '@feature/policies/policies.service';
import { AndroidSetting } from '@root/app/shared/android-settings.types';

export const formatAndroidSettings = (settings: AndroidSetting[]) => {
	return settings
		.filter((restriction) => restriction.value)
		.map(
			(restriction): DeviceNewAndroidPolicy => ({
				settingId: restriction.settingId,
				value: restriction.value,
			}),
		);
};
