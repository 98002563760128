import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

export interface SectionMenuItem {
	icon: string;
	label: string;
	route: string;
	active?: boolean;
	timer?: boolean;
	disabled?: boolean;
}

@Component({
	selector: 'csd-app-section-menu',
	standalone: true,
	imports: [MatIconModule, RouterLink],
	templateUrl: './section-menu.component.html',
	styleUrl: './section-menu.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionMenuComponent {
	itemsList = input.required<SectionMenuItem[]>();
	clicked = output<SectionMenuItem>();

	onClick(event: Event, item: SectionMenuItem) {
		if (item.disabled) {
			event.preventDefault();
			return;
		}

		this.clicked.emit(item);
	}
}
