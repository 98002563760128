import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { triggerOpenClose } from '@root/app/animations';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';

import { CustomersStore } from '../../customers/customers.store';
import { PoliciesService } from '../../policies/policies.service';
import { PoliciesStore } from '../../policies/policies.store';

@Component({
	selector: 'csd-app-general',
	standalone: true,
	imports: [ReactiveFormsModule, FormsModule, MatInputModule, LoadingIconComponent],
	templateUrl: './general.component.html',
	styleUrl: './general.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [triggerOpenClose],
})
export class GeneralComponent implements OnInit {
	readonly #customersStore = inject(CustomersStore);
	readonly #policiesStore = inject(PoliciesStore);
	#policiesService = inject(PoliciesService);
	#rootFormGroup = inject(FormGroupDirective);

	loadingPolicies = this.#policiesService.isLoading;

	get form() {
		return this.#rootFormGroup.control;
	}

	get hasNameError() {
		return this.form.dirty && this.form.get('name')?.invalid;
	}

	get nameControl() {
		return this.form.get('name') as FormControl;
	}

	ngOnInit(): void {
		const allGroups = this.#policiesStore.allGroupsNames();

		if (!allGroups.length) {
			this.getAllPolicies();
		}
	}

	getAllPolicies() {
		const customerId = this.#customersStore.selectedCustomerId();
		let request$ = this.#policiesService.getPublicPolicies();

		if (customerId) {
			const customerCode = this.#customersStore.selectedCustomer()?.code as string;
			request$ = this.#policiesService.getByCustomer(customerCode, customerId);
		}

		request$.subscribe(this.validateName.bind(this));
	}

	validateName() {
		const value = (this.nameControl.value ?? '').trim();
		const allGroups = this.#policiesStore.allGroupsNames();
		const nameExists = allGroups.includes(value);

		if (nameExists) {
			this.nameControl.setErrors({ nameExists: true });
			return;
		}

		this.nameControl.setErrors(null);
	}
}
