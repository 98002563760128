<form class="upload-card">
    <p>Select an APK file to install</p>
    <form class="max-w-sm">
        <label for="file-input" class="sr-only">Choose file</label>
        <input type="file" accept=".apk" name="file-input" id="file-input" class="upload-input" (change)="handleFileInput($event)" formControlName="file"/>
    </form>
    <div class="upload-button">
        <csd-app-button
            classList="w-full"
            type="outlined"
            variation="primary"
            [disabled]="sendDisabled()"
            (click)="sendAPK()"
        >
            {{ sending() ? 'Uploading - ' + progress() + '%' : 'Send APK' }}
        </csd-app-button>
    </div>
    @if (sending()) {
        <div class="upload-button">
            <csd-app-button
                classList="w-full"
                type="outlined"
                variation="error"
                (click)="cancelUpload()"
            >
                Cancel Upload
            </csd-app-button>
        </div>
    }
</form>