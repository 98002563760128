import { Component, computed, inject, input } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CustomersStore } from '@feature/customers/customers.store';
import { CompanyGroup } from '@feature/groups/groups.types';
import { UserAreaComponent } from '@feature/user-area/user-area.component';
import { UserService } from '@feature/user/user.service';
import { UserStore } from '@feature/user/user.store';
import { ButtonComponent } from '@ui/button/button.component';

import { MainLayoutService } from '../main-layout.service';
import { CONFIG_BUTTONS, CUSTOMER_BUTTONS } from './sidebar.constants';

@Component({
	selector: 'csd-app-sidebar',
	standalone: true,
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.scss',
	imports: [ButtonComponent, RouterLink, RouterLinkActive, UserAreaComponent],
})
export class SidebarComponent {
	readonly #customerStore = inject(CustomersStore);
	readonly #userStore = inject(UserStore);
	#mainLayoutService = inject(MainLayoutService);
	#router = inject(Router);
	#userService = inject(UserService);
	isMobile = input(false);

	customer = this.#customerStore.selectedCustomer;
	customerButtons = CUSTOMER_BUTTONS;
	selectedGroup = this.#userService.userSelectedGroup;
	shouldDisplayDashboard = this.#userService.shouldDisplayDashboard;

	configButtonsByGroup = computed(() =>
		CONFIG_BUTTONS.filter((buttonData) => {
			if (!buttonData.restrictTo) {
				return true;
			}

			return buttonData.restrictTo.some(
				(role) => this.selectedGroup()?.[role as keyof CompanyGroup],
			);
		}),
	);
	configButtonsByRole = computed(() =>
		this.configButtonsByGroup().filter((buttonData) => {
			if (!buttonData.roles) {
				return true;
			}

			const userGroups = this.#userStore.user().groups;
			return userGroups.some((group) => buttonData.roles?.includes(group.toLowerCase()));
		}),
	);
	configButtons = computed(() => {
		return this.configButtonsByRole().map((buttonData) => {
			const customerCode = this.customer()?.code;
			const linkToReplace = customerCode ? `customer/${customerCode}/` : '/';

			return {
				...buttonData,
				path: buttonData.path.replace('{customer}/', linkToReplace),
			};
		});
	});

	closeSidenav() {
		if (this.isMobile()) {
			this.#mainLayoutService.toggleSidenav(false);
		}
	}

	getPath(route: string) {
		return ['customer', this.customer()?.code, route];
	}

	validateActiveRoute(route: string): boolean {
		const routeRegex = new RegExp(`${route}`, 'gi');

		return !!this.#router.url.match(routeRegex);
	}
}
