<div class="app-item" [ngClass]="classList()">
	<div
		class="app-item__info text-layout-blue-jay"
		[mtxPopoverTriggerFor]="appInfoPopover"
		[mtxPopoverTriggerData]="data()?.appResponse"
	>
		<div class="flex items-center">
			<img
				[src]="getLogo(data()?.appResponse?.iconURL)"
				[alt]="data()?.appResponse?.name"
				class="w-full max-w-8 mr-2"
			/>
			<p class="text-base font-bold max-h-10 overflow-hidden leading-tight truncate">
				{{ data()?.appResponse?.name }}
			</p>
		</div>
		<p class="text-xs truncate">{{ data()?.appResponse?.packageName }}</p>
	</div>
	<div class="app-item__status flex flex-col items-end gap-y-2">
		@if (vpnOn()) {
			<mat-select
				class="status-dropdown"
				[value]="data()?.internetConfig"
				[compareWith]="compareSimpleValues"
				(valueChange)="onSelectChange('internetConfig', $event)"
				hideSingleSelectionIndicator
			>
				@for (item of vpnConfigOptions(); track $index + item.label) {
					<mat-option class="option-small" [value]="item.value">{{ item.label }}</mat-option>
				}
			</mat-select>
		}
		<div class="flex items-center">
			@if (data()?.differentThanGroup) {
				<span
					class="text-layout-scarlet-red text-xl align-middle leading-none px-2 cursor-pointer"
					[mtxPopoverTriggerFor]="errorPopover"
				>
					<mat-icon inline class="material-symbols-filled">info</mat-icon>
				</span>
			}
			<mat-select
				class="status-dropdown"
				[value]="appStatusFromData()"
				[compareWith]="compareSimpleValues"
				(valueChange)="onSelectChange('status', $event)"
				hideSingleSelectionIndicator
			>
				@for (item of activeOptions; track $index + item.label) {
					<mat-option class="option-small" [value]="item.value">{{ item.label }}</mat-option>
				}
			</mat-select>
		</div>
	</div>
</div>

<mtx-popover #errorPopover="mtxPopover" [position]="position">
	<ng-template mtxPopoverContent let-name="name">
		<p class="flex items-center flex-wrap max-w-36 xs:max-w-96 gap-3 justify-center text-center">
			Configuration differs from group policy
			<button
				class="text-error-secondary rounded-xl bg-error-primary px-3"
				matRipple
				(click)="reset.emit()"
			>
				Reset
			</button>
		</p>
	</ng-template>
</mtx-popover>

<mtx-popover #appInfoPopover="mtxPopover" [enterDelay]="1000">
	<ng-template mtxPopoverContent let-name="name" let-packageName="packageName">
		<p class="flex flex-col gap-1">
			<strong>{{ name }}</strong>
			<span class="text-xs">{{ packageName }}</span>
		</p>
	</ng-template>
</mtx-popover>
