<form class="general-form" [formGroup]="form">
	<div class="general-form__fields">
		<div class="form-element relative">
			<label for="name">Policy Name</label>
			<input
				matInput
				id="name"
				formControlName="name"
				placeholder="Name"
				(focusout)="validateName()"
			/>
			@if (loadingPolicies()) {
				<csd-app-loading-icon classList="absolute top-9 right-2" />
			}
			@if (hasNameError) {
				<div @openClose class="text-error-tertiary px-1">
					@if (nameControl.errors?.['required']) {
						Please enter a policy name.
					}
					@if (nameControl.errors?.['pattern']) {
						Only letters, numbers, space and special characters (- or ') are allowed.
					}
					@if (nameControl.errors?.['nameExists']) {
						A policy with this name already exists.
					}
				</div>
			}
		</div>
		<div class="form-element">
			<label for="group">Church Group</label>
			<input
				matInput
				id="group"
				formControlName="churchCode"
				placeholder="If a church admin, would be assumed"
			/>
		</div>
	</div>
</form>
