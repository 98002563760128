export const COMMAND_BUTTONS = [
	{
		icon: 'lock',
		label: 'Lock',
		command: 'lock',
		isLoading: false,
	},
	{
		icon: 'autorenew',
		label: 'Restart',
		command: 'restart',
		isLoading: false,
	},
	{
		icon: 'verified_user',
		label: 'Refresh Certs',
		command: 'refreshCerts',
		isLoading: false,
	},
	{
		icon: 'lock_open_right',
		label: 'Clear Screen Lock',
		command: 'clearScreenLock',
		isLoading: false,
	},
];

export const ANDROID_COMMANDS: Record<string, number> = {
	lock: 0,
	restart: 2,
	refreshCerts: 4,
	clearScreenLock: 7,
};
