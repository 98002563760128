import { inject, Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { Note } from '@feature/device-details/device-details.types';
import { tap } from 'rxjs';

import { NotesStore } from './notes.store';

export interface NotesUpdate {
	identifier: string;
	commenter: string;
	comment: string;
	pinned: boolean;
	timeCommented?: string;
}

@Injectable({
	providedIn: 'root',
})
export class NotesService {
	readonly #notesStore = inject(NotesStore);
	#apiService = inject(ApiService);

	loadNotes(deviceId: string) {
		return this.#apiService
			.get<Note[]>(`/notes/getbyidentifier/${deviceId}`)
			.pipe(tap((notes) => this.#notesStore.setNotes(notes ?? [])));
	}

	saveNote(data: NotesUpdate) {
		return this.#apiService.post<string>('/notes', data);
	}

	updateNote(id: string, data: NotesUpdate) {
		return this.#apiService
			.put<Note>(`/notes/${id}`, data)
			.pipe(tap((newData) => this.#notesStore.updateNote(id, newData)));
	}
}
