<div class="group-detail">
	<div class="group-detail__header">
		<csd-app-header />
		<div class="group-detail__actions">
			<button class="action-button action-button--alert" (click)="confirmRemoveUser()">
				Leave
			</button>
			<button class="action-button">Add User</button>
		</div>
	</div>
	<div class="group-detail__table">
		<table mat-table [dataSource]="dataSource()">
			<!-- Name Column -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>Name</th>
				<td mat-cell *matCellDef="let element" [class.user-detail--empty]="!element.user.name">
					{{ element.user.name || 'No name' }}
				</td>
			</ng-container>

			<!-- Username Column -->
			<ng-container matColumnDef="username">
				<th mat-header-cell *matHeaderCellDef>Username</th>
				<td mat-cell *matCellDef="let element" [class.user-detail--empty]="!element.user.name">
					{{ element.user?.userName || 'No username' }}
				</td>
			</ng-container>

			<!-- Role Column -->
			<ng-container matColumnDef="role">
				<th mat-header-cell *matHeaderCellDef>Role</th>
				<td
					mat-cell
					*matCellDef="let element; let i = index"
					class="min-w-20 w-[10%] max-w-32 text-center"
				>
					<mat-select
						class="status-dropdown"
						hideSingleSelectionIndicator
						[value]="element.status"
						[disabled]="selectedUserIndex() === i"
						(selectionChange)="onChangeStatus($event, i)"
					>
						@for (item of userStatusOptions; track item.label) {
							<mat-option [value]="item.value">{{ item.label }}</mat-option>
						}
					</mat-select>
				</td>
			</ng-container>

			<!-- Actions Column -->
			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef>Actions</th>
				<td mat-cell *matCellDef="let element" class="min-w-16 w-[10%] max-w-20 text-center">
					<button class="leading-none p-3 rounded" matRipple (click)="confirmRemoveUser(element)">
						<mat-icon class="material-symbols-filled align-middle">person_remove</mat-icon>
					</button>
				</td>
			</ng-container>

			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
	</div>
</div>
