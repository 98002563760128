<div
	cdkDropList
	[cdkDropListDisabled]="disableDrop()"
	class="space-y-3"
	(cdkDropListDropped)="drop($event)"
>
	@for (rule of list(); track $index) {
		<a
			class="sortable-item cdk-drag-box"
			cdkDrag
			[routerLink]="rule.canEdit ? rule['link'] : null"
			(click)="selectPolicy(rule)"
		>
			@if (!disableDrop()) {
				<div class="cdk-drag-handle" cdkDragHandle>
					<mat-icon inline class="align-middle">expand_all</mat-icon>
				</div>
				<div class="cdk-drag-placeholder" *cdkDragPlaceholder></div>
			}
			<div class="content-box w-full flex items-center">
				<div class="w-full sm:w-auto flex flex-col sm:flex-row sm:grow">
					<p class="grow m-0">{{ rule['name'] }}</p>
					@if (rule['tag']['name']) {
						<csd-app-tag [classList]="'px-4 ' + getColorVariation(rule.tag.type)">
							{{ rule.tag.name }}
						</csd-app-tag>
					}
				</div>

				@if (rule.canEdit) {
					<div class="flex justify-end sm:justify-center min-w-fit">
						<div class="item-actions pl-2">
							<button
								class="text-layout-scarlet-red rounded px-2 py-1 text-xl leading-none"
								(click)="onDelete($event, rule)"
								matRipple
							>
								<mat-icon inline class="align-middle">delete</mat-icon>
							</button>
						</div>
					</div>
				}
			</div>
		</a>
	}
</div>
