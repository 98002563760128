import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	OnInit,
	output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterLink } from '@angular/router';
import { Customer } from '@feature/customer/customer.types';
import { CustomersStore } from '@feature/customers/customers.store';
import { DevicesStore } from '@feature/devices/devices.store';
import { PoliciesService } from '@feature/policies/policies.service';
import { PoliciesStore } from '@feature/policies/policies.store';
import { UserStore } from '@feature/user/user.store';
import { selectCompareValue } from '@utils/select-compare-value';

@Component({
	selector: 'csd-app-policy',
	standalone: true,
	imports: [MatSelectModule, RouterLink, ReactiveFormsModule, MatIconModule],
	templateUrl: './policy.component.html',
	styleUrl: './policy.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyComponent implements OnInit {
	#customersStore = inject(CustomersStore);
	#destroyRef = inject(DestroyRef);
	#devicesStore = inject(DevicesStore);
	#policiesService = inject(PoliciesService);
	#policiesStore = inject(PoliciesStore);
	#rootFormGroup = inject(FormGroupDirective);
	#router = inject(Router);
	#userStore = inject(UserStore);

	device = this.#devicesStore.selectedDevice;
	groups = this.#policiesStore.allGroups;
	selectCompareValueFn = selectCompareValue;

	changed = output<string>();

	get form() {
		return this.#rootFormGroup.control;
	}

	ngOnInit(): void {
		this.getPoliciesList();
	}

	getPoliciesList() {
		const customer = this.#customersStore.selectedCustomer() as Customer;
		const customerCode = customer.code;
		const customerId = customer.id;

		if (this.#policiesStore.allGroups().length) {
			return;
		}

		this.#policiesService
			.getByCustomer(customerCode, customerId)
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe();
	}

	saveCurrentLink() {
		this.#userStore.setDeviceToReturn(this.#router.url);
	}
}
