import { computed, inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { finalize, tap } from 'rxjs';

import { GroupsStore } from '../groups/groups.store';
import { CompanyGroup } from '../groups/groups.types';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	readonly #groupsStore = inject(GroupsStore);
	#apiService = inject(ApiService);
	#userGroups = this.#groupsStore.groupsList;

	isLoadingGroups = signal<boolean>(false);

	shouldDisplayDashboard = computed<boolean>(() => {
		const hasUserGroups = !!this.#userGroups()?.length;
		return hasUserGroups && this.#userGroups()?.some((group) => group.dashboard);
	});

	getListContent(userId: string) {
		this.isLoadingGroups.set(true);

		return this.#apiService.get<CompanyGroup[]>(`/access/listcontent/${userId}`).pipe(
			tap((data) => {
				data.forEach((group) => this.#groupsStore.addGroup(group));
			}),
			finalize(() => this.isLoadingGroups.set(false)),
		);
	}
}
