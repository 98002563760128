import { ChangeDetectionStrategy, Component, effect, inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { NotificationService } from '@ui/notification/notification.service';
import { finalize, Subscription } from 'rxjs';

import { CustomersService } from '../../customers/customers.service';
import { CustomersStore } from '../../customers/customers.store';
import { DataNotifications } from '../customer.types';
import { DAYS_ALERTED, RECURRING_DAYS } from './overview.constants';

@Component({
	selector: 'csd-app-overview',
	standalone: true,
	imports: [
		EmptyStateComponent,
		HeaderDividerComponent,
		MatIconModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatTooltipModule,
		ReactiveFormsModule,
		RouterLink,
	],
	templateUrl: './overview.component.html',
	styleUrl: './overview.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewComponent implements OnDestroy {
	readonly #store = inject(CustomersStore);
	#customersService = inject(CustomersService);
	#fb = inject(FormBuilder);
	#notificationService = inject(NotificationService);

	customer = this.#store.selectedCustomer;
	daysAlerted = DAYS_ALERTED;
	form = this.#fb.group({
		dataUsageNotices: [true, [Validators.required]],
		frequencyInDays: [1, [Validators.required, Validators.min(1)]],
		spanBeforeAlertInDays: [1, [Validators.required, Validators.min(1)]],
	});
	recurringDays = RECURRING_DAYS;
	subscriptions$: Subscription[] = [];

	constructor() {
		effect(() => {
			const { dataNotificationPreferences } = this.customer() || {};

			if (dataNotificationPreferences) {
				this.form.patchValue({ ...dataNotificationPreferences });
			}
		});
	}

	ngOnDestroy(): void {
		this.subscriptions$.forEach((subs$) => subs$.unsubscribe());
	}

	getControl(name: string) {
		return this.form.get(name) as FormControl;
	}

	dataUsageChange(event: MatSlideToggleChange) {
		const isChecked = event.checked;

		if (isChecked) {
			this.getControl('frequencyInDays').setValue(1);
			this.getControl('spanBeforeAlertInDays').setValue(1);
		}

		this.updateControlStatus(isChecked);
		this.updateChanges();
	}

	updateControlStatus(dataUsageNotices: boolean) {
		const action = dataUsageNotices ? 'enable' : 'disable';

		this.getControl('frequencyInDays')[action]();
		this.getControl('spanBeforeAlertInDays')[action]();
	}

	updateChanges() {
		const { code } = this.customer() || {};
		const value = this.form.value as Partial<DataNotifications>;

		this.form.markAsDirty();

		if (!code || !this.form.valid) {
			return;
		}

		const data: Record<string, unknown> = {
			...this.customer(),
			dataNotificationPreferences: {
				...value,
				frequencyInDays: value.dataUsageNotices ? value.frequencyInDays : 0,
				spanBeforeAlertInDays: value.dataUsageNotices ? value.spanBeforeAlertInDays : 0,
			},
		};

		this.form.disable();
		this.#notificationService.open({ message: 'Updating preferences...' });
		const update$ = this.#customersService
			.updateCustomer(code, data)
			.pipe(
				finalize(() => {
					const dataUsageChangeControl = this.form.get('dataUsageNotices');

					if (dataUsageChangeControl?.value) {
						this.form.enable();
						return;
					}

					dataUsageChangeControl?.enable();
				}),
			)
			.subscribe(() => {
				this.#store.updateCustomerDetail(this.customer()?.code as string, data);
				this.#notificationService.openSuccess({ message: 'Preferences updated' });
			});

		this.subscriptions$.push(update$);
	}
}
