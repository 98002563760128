<form class="general-form" [formGroup]="form" (ngSubmit)="onSave($event)">
	@if (currentGroup()?.customerUserCode) {
		<div class="pb-4">
			<csd-app-tag classList="bg-tag-user text-white">{{
				currentGroup()?.customerUserCode
			}}</csd-app-tag>
		</div>
	}
	<div class="general-form__fields">
		<div class="form-element relative">
			<label for="name">Policy Name</label>
			<input
				matInput
				id="name"
				formControlName="name"
				placeholder="Name"
				(focusout)="validateName()"
			/>
			@if (loadingPolicies()) {
				<csd-app-loading-icon classList="absolute top-9 right-2" />
			}
			@if (hasNameError) {
				<div @openClose class="text-error-tertiary px-1">
					@if (nameControl.errors?.['required']) {
						Please enter a policy name.
					}
					@if (nameControl.errors?.['pattern']) {
						Only letters, numbers, space and special characters (- or ') are allowed.
					}
					@if (nameControl.errors?.['nameExists']) {
						A policy with this name already exists.
					}
				</div>
			}
		</div>
		<div class="form-element">
			<label for="group">Church Group</label>
			<input
				matInput
				id="group"
				formControlName="churchCode"
				placeholder="If a church admin, would be assumed"
			/>
		</div>
		<div class="form-element">
			<label>
				Policy Owner
				<mat-icon
					class="cursor-pointer align-bottom px-2"
					inline
					[mtxPopoverTriggerFor]="popover"
					mtxPopoverTriggerOn="hover"
					>info</mat-icon
				>
			</label>
			<mat-select formControlName="ownerId" hideSingleSelectionIndicator>
				@for (owner of ownerOptions(); track owner.id) {
					<mat-option [value]="owner.id">{{ owner.name }}</mat-option>
				}
			</mat-select>
		</div>
	</div>

	<div class="general-form__actions">
		<csd-app-button classList="w-28" routerLink="../../">Cancel</csd-app-button>
		<csd-app-button
			classList="w-28"
			variation="secondary"
			htmlType="submit"
			[disabled]="isLoading()"
		>
			Save
			@if (isLoading()) {
				<csd-app-loading-icon />
			}
		</csd-app-button>
	</div>
</form>

<mtx-popover #popover="mtxPopover" [closeOnBackdropClick]="true" [hasBackdrop]="true">
	<p class="max-w-36 xs:max-w-96">
		When this is set, any apps installed by a device on this group will need to be aproved, and will
		be checked by this device.
	</p>
</mtx-popover>
