<div class="search">
	<input
		class="search__input"
		type="text"
		[placeholder]="placeholder()"
		[(ngModel)]="search"
		(input)="onChange()"
		matInput
	/>
	@if (search()) {
		<button class="search__button" matRipple (click)="clearSearch()">
			<mat-icon inline class="align-text-top leading-none">cancel</mat-icon>
		</button>
	}
</div>
