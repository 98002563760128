import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	ElementRef,
	inject,
	OnDestroy,
	signal,
	ViewChild,
} from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { ConfigStore } from '@feature/config/config.store';
import { TABLE_HEADERS } from '@feature/policy-details/apps/apps.constants';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';
import { ACTIVE_OPTIONS, VPN_CONFIG_LABELS } from '@ui/app-item/app-item.constants';
import { ButtonComponent } from '@ui/button/button.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { AddPolicyApp } from '@ui/modal/add-policy/add-policy.types';
import { ModalService } from '@ui/modal/modal.service';
import { Cache } from '@utils/decorators/cache.decorator';

@Component({
	selector: 'csd-app-apps-list',
	standalone: true,
	imports: [
		ButtonComponent,
		EmptyStateComponent,
		FormsModule,
		MatIcon,
		MatInputModule,
		MatTableModule,
		ReactiveFormsModule,
	],
	templateUrl: './apps-list.component.html',
	styleUrl: './apps-list.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsListComponent implements OnDestroy {
	@ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

	readonly #configStore = inject(ConfigStore);
	#rootFormGroup = inject(FormGroupDirective);
	#modalService = inject(ModalService);

	appOptions = this.#configStore.appOptions;
	displayedColumns: string[] = TABLE_HEADERS;
	searchAppTerm = signal('');
	totalSelectedApps = signal<number>(this.appsList.length || 0);

	dataSource = computed(() => {
		if (this.searchAppTerm() === '') {
			return this.totalSelectedApps() ? this.appsList : [];
		}

		return this.appsList.filter(this.filterAppList.bind(this));
	});

	constructor() {
		effect(() => {
			if (this.appOptions()?.vpnDirectOptions) {
				Cache.clear();
			}
		});
	}

	get form() {
		return this.#rootFormGroup.control;
	}

	get appsList() {
		return this.form.get('apps')?.value || [];
	}

	get appsListControl() {
		return this.form.get('apps');
	}

	ngOnDestroy(): void {
		Cache.clear();
	}

	addAppToList(app: AndroidAppConfig) {
		this.appsListControl?.setValue([...this.appsList, app]);
		this.totalSelectedApps.set(this.appsList.length);
		this.searchAppTerm.set('');
	}

	clearSearch() {
		this.searchInput.nativeElement.value = '';
		this.searchAppTerm.set('');
	}

	@Cache()
	getAppStatus(status: string | number) {
		return ACTIVE_OPTIONS.find((option) => option.value === +status)?.label;
	}

	@Cache()
	getVpnStatus(status: string) {
		const { vpnOptions } = this.appOptions() || {};

		if (!vpnOptions) {
			return status;
		}
		return (
			VPN_CONFIG_LABELS[(vpnOptions?.[status] || '').toLowerCase()] ||
			vpnOptions?.[status] ||
			status
		);
	}

	handleCloseModal(data?: AndroidAppConfig) {
		this.#modalService.close();

		if (!data) {
			return;
		}

		this.addAppToList(data);
	}

	filterAppList(appPolicy: AddPolicyApp) {
		const value = this.searchAppTerm();
		const { appResponse, status, internetConfig } = appPolicy;
		const lowerCaseValue = value?.toLowerCase();
		const isNameMatch = appResponse?.name.toLowerCase().includes(lowerCaseValue);
		const isPackageMatch = appResponse?.packageName.toLowerCase().includes(lowerCaseValue);
		const isStatusMatch =
			this.getAppStatus(status)?.toLowerCase().includes(lowerCaseValue) ||
			status === +lowerCaseValue;
		const isVpnMatch =
			this.getVpnStatus(`${internetConfig}`)?.toLowerCase().includes(lowerCaseValue) ||
			internetConfig === +lowerCaseValue;

		return isNameMatch || isPackageMatch || isStatusMatch || isVpnMatch;
	}

	async openAddAppModal() {
		await this.#modalService.open('add-policy', { addedApps: this.appsList });
		this.#modalService.afterClose(this.handleCloseModal.bind(this));
	}

	removeAppFromList(index: number) {
		const newAppList = [...this.appsList];

		newAppList.splice(index, 1);
		this.appsListControl?.setValue(newAppList);
		this.totalSelectedApps.set(this.appsList.length);
	}

	searchApp(event: KeyboardEvent) {
		const element = event.target as HTMLInputElement;
		const cleanValue = element.value?.trim() || '';

		if (event.key === 'Enter') {
			return;
		}

		this.searchAppTerm.set(cleanValue);
	}
}
