import { inject, Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { tap } from 'rxjs';

import { UsersStore } from './users.store';

export interface AuthentikUser {
	sub: string;
	name: string;
	userName: string;
	email: string;
	status?: number;
	dashboard?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	#apiService = inject(ApiService);
	#usersStore = inject(UsersStore);

	getAuthentikUsers() {
		return this.#apiService.get<AuthentikUser[]>('/access/getauthentikusers').pipe(
			tap((users) => {
				if (!Array.isArray(users)) {
					return;
				}

				users.forEach((user) => {
					this.#usersStore.updateUser(user.userName, user);
				});
			}),
		);
	}
}
