<div class="stats-page pb-5" [formGroup]="form">
	<csd-app-header (nameChanged)="onNameChanged($event)" />

	<section class="details-section pt-5">
		<div class="details-section__status">
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">campaign</mat-icon>
					Status
				</span>
				<span
					class="status-item__value status-button"
					[class.status-button--active]="device().active"
					>{{ device().active ? 'Active' : 'Disabled' }}</span
				>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">sell</mat-icon>
					Subscription
				</span>
				<span class="status-item__value">
					{{ device().workOrder }}
					<button class="leading-none" (click)="openWorkOrder()" matRipple>
						<mat-icon inline class="material-symbols-filled align-bottom">edit</mat-icon>
					</button>
				</span>
			</p>
			<p class="status-item pb-5">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">schedule</mat-icon>
					Last Seen
				</span>
				<span class="status-item__value">{{ device().lastPullProfile | date }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">id_card</mat-icon>
					License #
				</span>
				<span class="status-item__value">{{ device().license }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">verified</mat-icon>
					Version
				</span>
				<span class="status-item__value">{{
					device().appDisplayVersion || device().appVersion
				}}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">android</mat-icon>
					System
				</span>
				<span class="status-item__value">{{ device().androidVersion }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">style</mat-icon>
					Updates Channel
				</span>
				<span class="status-item__value">
					<mat-select
						class="channel-selection"
						hideSingleSelectionIndicator
						formControlName="releaseChannel"
						[compareWith]="selectCompareValueFn"
						(selectionChange)="updateDevice('releaseChannel')"
					>
						@for (channel of updatesChannels; track channel.value) {
							<mat-option [value]="channel.value">{{ channel.label }}</mat-option>
						}
					</mat-select>
				</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled align-sub">lock</mat-icon>
					Unlock Code
				</span>
				<span class="status-item__value">
					<csd-app-uninstall-key
						[text]="device().uninstallKey"
						(changed)="updateDevice('uninstallKey')"
					/>
				</span>
			</p>
		</div>
		<div class="details-section__device-info">
			@for (item of statusItems; track item.property) {
				<p class="status-item status-item--with-separator">
					<span class="status-item__label">{{ item.label }}</span>
					<span class="status-item__value">{{ device()[item.property] }}</span>
				</p>
			}
		</div>
		@if (device().lastPullProfile == null) {
			<div class="details-section__qr-info">
				<p class="status-item">
					<span class="status-item__label">Enroll with a QR Code</span>
				</p>
				<img [src]="qrCodeUrl" alt="QR Code" width="100%" />
			</div>
		}
	</section>
	<section class="device-section">
		<csd-app-header-divider text="Policy" />
		<div class="device-section__content">
			<csd-app-policy (changed)="updateDevice($event)" />
		</div>
	</section>
	<section class="device-section">
		<csd-app-header-divider text="Device Commands" />
		<div class="device-section__content">
			<csd-app-commands [deviceId]="device().id" />
		</div>
	</section>
</div>
