import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { UserService } from '@feature/user/user.service';
import { ButtonGroupComponent } from '@ui/button-group/button-group.component';
import { ButtonComponent } from '@ui/button/button.component';
import { UserImageComponent } from '@ui/user-image/user-image.component';

import { GroupsStore } from '../groups/groups.store';
import { CompanyGroup } from '../groups/groups.types';
import { UserStore } from '../user/user.store';

@Component({
	selector: 'csd-app-user-area',
	standalone: true,
	templateUrl: './user-area.component.html',
	styleUrl: './user-area.component.scss',
	imports: [
		ButtonComponent,
		ButtonGroupComponent,
		MatExpansionModule,
		MatIconModule,
		UserImageComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAreaComponent implements OnInit, AfterViewInit {
	@ViewChild(MatExpansionPanel) expansionPanel!: MatExpansionPanel;

	readonly #userStore = inject(UserStore);
	readonly #groupsStore = inject(GroupsStore);
	#authService = inject(AuthService);
	#router = inject(Router);
	#userService = inject(UserService);

	groupList = computed(() => {
		return this.#groupsStore.groupsList().filter((group) => group.dashboard);
	});
	userName = computed(() => this.#userStore.user()?.name ?? '');

	ngOnInit(): void {
		const groupsList = this.#groupsStore.groupsList();

		if (groupsList.length) {
			this.updateListContent(groupsList);
			return;
		}

		this.#userService
			.getListContent(this.#userStore.user()?.sub as string)
			.subscribe(this.updateListContent.bind(this));
	}

	ngAfterViewInit(): void {
		this.expansionPanel._body.nativeElement.classList.add('user-area__content');
	}

	getGroupName(group: CompanyGroup) {
		return group.groupName || group.access;
	}

	isActiveGroup(groupId: string, isFirst: boolean) {
		const selectedGroup = this.#userStore.selectedGroupId();

		return selectedGroup ? selectedGroup === groupId : isFirst;
	}

	logout() {
		this.#authService.logout().subscribe(() => {
			this.#router.navigate(['authentication', 'login']);
		});
	}

	openConfig(groupId: string) {
		this.#router.navigate(['group', groupId]);
	}

	updateListContent(data: any) {
		this.#userStore.setSelectedGroupId(data?.[0]?.access);
	}

	updateSelectedGroup(groupId: string) {
		this.#userStore.setSelectedGroupId(groupId);
	}
}
