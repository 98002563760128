import { Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

import { AppsComponent } from './feature/apps/apps.component';
import authRoutes from './feature/auth/auth.routes';
import { customerResolver } from './feature/customer/customer.resolver';
import customerRoutes from './feature/customer/customer.routes';
import customersRoutes from './feature/customers/customers.routes';
import devicesRoutes from './feature/devices/devices.routes';
import { groupDetailRoutes } from './feature/group-detail/group-detail.routes';
import onboardingRoutes from './feature/onboarding/onboarding.routes';
import policiesRoutes from './feature/policies/policies.routes';
import { ErrorLayoutComponent } from './layout/error-layout/error-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { OnboardingLayoutComponent } from './layout/onboarding-layout/onboarding-layout.component';
import { PoliciesLayoutComponent } from './layout/policies-layout/policies-layout.component';

const baseTitle = '| eMyPeople';

export const appRoutes: Routes = [
	{
		path: 'authentication',
		component: LoginLayoutComponent,
		title: `Authenticate ${baseTitle}`,
		children: authRoutes,
	},
	{
		path: 'onboarding',
		component: OnboardingLayoutComponent,
		title: `Onboarding ${baseTitle}`,
		children: onboardingRoutes,
		canActivate: [AutoLoginPartialRoutesGuard],
	},
	{
		path: 'apps',
		redirectTo: 'customer/public/apps',
		pathMatch: 'full',
	},
	{
		path: 'policies',
		redirectTo: 'customer/public/policies',
		pathMatch: 'full',
	},
	{
		path: '',
		component: MainLayoutComponent,
		title: `Control Dashboard ${baseTitle}`,
		canActivate: [AutoLoginPartialRoutesGuard],
		children: [
			{
				path: '',
				children: customersRoutes,
			},
			{
				path: 'error',
				component: ErrorLayoutComponent,
			},
			{
				path: 'customer/:customerId',
				resolve: { customer: customerResolver },
				data: {
					breadcrumb: (data: any) => (data?.customer ? data?.customer.code?.toUpperCase() : ''),
				},
				children: [
					{
						path: '',
						redirectTo: (route) => {
							return `/${encodeURIComponent(route.params['customerId'])}/overview`;
						},
						pathMatch: 'full',
					},
					{
						path: 'overview',
						title: `Overview ${baseTitle}`,
						data: { breadcrumb: 'Overview' },
						children: customerRoutes,
					},
					{
						path: 'devices',
						title: `Devices ${baseTitle}`,
						data: { breadcrumb: 'Devices' },
						children: devicesRoutes,
					},
					{
						path: 'policies',
						component: PoliciesLayoutComponent,
						data: { breadcrumb: 'Policies' },
						children: policiesRoutes,
					},
					{
						path: 'apps',
						title: `Apps ${baseTitle}`,
						component: AppsComponent,
						data: { breadcrumb: 'Apps' },
					},
				],
			},
			...groupDetailRoutes,
		],
	},
	{
		path: '**',
		redirectTo: '/error',
	},
];
