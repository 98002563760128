<div class="app-item bg-layout-sky rounded flex justify-between items-center pr-3">
	<mat-checkbox class="app-item__checkbox" [checked]="checked()" (change)="update($event.checked)">
		{{ text() }}
	</mat-checkbox>
	@if (showReset()) {
		<span
			class="text-layout-scarlet-red text-xl leading-none cursor-pointer"
			[mtxPopoverTriggerFor]="popover"
			mtxPopoverTriggerOn="hover"
		>
			<mat-icon inline class="material-symbols-filled align-middle">info</mat-icon>
		</span>

		<mtx-popover #popover="mtxPopover">
			<p class="flex items-center flex-wrap max-w-36 xs:max-w-96 gap-3 justify-center text-center">
				Configuration differs from group policy
				<button
					class="text-error-secondary rounded-xl bg-error-primary px-3"
					matRipple
					(click)="reset.emit()"
				>
					Reset
				</button>
			</p>
		</mtx-popover>
	}
</div>
