import { Routes } from '@angular/router';

import { CompanyGroup, UserGroup } from '../groups/groups.types';
import { GroupDetailComponent } from './group-detail.component';
import { groupDetailResolver } from './group-detail.resolver';

const baseTitle = '| eMyPeople';

export const groupDetailRoutes: Routes = [
	{
		path: 'group/:groupId',
		resolve: { groupData: groupDetailResolver },
		title: `Group ${baseTitle}`,
		data: {
			breadcrumb: (data: { groupData: [CompanyGroup, UserGroup[]] }) => {
				const [group] = data.groupData;

				if (group.groupName) {
					return group.groupName;
				}

				const access = group.access;
				const accessLength = access.length;

				if (accessLength > 10) {
					return access.substring(0, 10) + '...';
				}

				return access;
			},
		},
		component: GroupDetailComponent,
	},
];
