import { Routes } from '@angular/router';
import { AppsComponent } from '@feature/apps/apps.component';
import { CredentialsComponent } from '@feature/credentials/credentials.component';
import usersRoutes from '@feature/users/users.routes';
import { ConfigurationLayoutComponent } from '@layout/configuration-layout/configuration-layout.component';
import { EmptyLayoutComponent } from '@layout/empty-layout/empty-layout.component';
import { BASE_TITLE } from '@root/app/app.constants';

import { configResolver } from './config.resolver';

export default <Routes>[
	{
		path: 'configuration',
		component: ConfigurationLayoutComponent,
		title: `Configuration ${BASE_TITLE}`,
		data: { breadcrumb: 'Configuration' },
		resolve: [configResolver],
		children: [
			{
				path: 'apps',
				title: `Apps ${BASE_TITLE}`,
				component: AppsComponent,
				data: { breadcrumb: 'Apps' },
			},
			{
				path: 'users',
				title: `Users ${BASE_TITLE}`,
				data: { breadcrumb: 'Users' },
				component: EmptyLayoutComponent,
				children: usersRoutes,
			},
			{
				path: 'credentials',
				component: CredentialsComponent,
				title: `Credentials ${BASE_TITLE}`,
				data: { breadcrumb: 'Credentials' },
			},
		],
	},
];
