import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

@Component({
	selector: 'csd-app-button-group',
	standalone: true,
	imports: [CommonModule, MatRippleModule],
	templateUrl: './button-group.component.html',
	styleUrl: './button-group.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupComponent {
	classList = input('', {
		transform: (value: string | string[]): string => {
			return Array.isArray(value) ? value.join(' ') : value;
		},
	});

	clicked = output<void>();
	clickedAfter = output<void>();
}
