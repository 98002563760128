<div class="device-apps">
	@if (devicePolicyApps().length) {
		<div class="search-apps">
			<csd-app-input-search
				(changed)="filterApps($event)"
				[search]="searchText"
				placeholder="Search apps"
			/>
		</div>
	}

	<csd-app-loader [isLoading]="isLoading()">
		@if (launcherApps().length) {
			<csd-app-header-divider text="Launcher Apps" />
			<csd-app-apps-list
				[appsList]="launcherFilterApps()"
				[deviceId]="device().id"
				[vpnOn]="!!device().vpnOn"
				listType="launcher"
				(appChanged)="updateAppInStore($event.appId, $event.data)"
			/>
		}

		@if (systemApps().length) {
			<csd-app-header-divider text="System Apps" />
			<csd-app-apps-list
				[appsList]="systemFilterApps()"
				[deviceId]="device().id"
				[vpnOn]="!!device().vpnOn"
				listType="system"
				(appChanged)="updateAppInStore($event.appId, $event.data)"
			/>
		}

		@if (!devicePolicyApps().length) {
			<div class="py-10">
				<csd-app-empty-state icon="apps">
					No apps added to manage this device.
				</csd-app-empty-state>
			</div>
		}
	</csd-app-loader>
</div>
