import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	input,
	OnDestroy,
	OnInit,
	output,
	signal,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { interval, Subscription } from 'rxjs';

const ANIMATION_TIME = 500;

@Component({
	selector: 'csd-app-pinned-notes',
	standalone: true,
	imports: [MatIconModule, MatRippleModule, NgClass],
	templateUrl: './pinned-notes.component.html',
	styleUrl: './pinned-notes.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('rotateNotes', [
			transition(':enter', [
				style({ transform: 'translateY(-100%)', opacity: 0 }),
				animate(`${ANIMATION_TIME}ms ease-in`, style({ transform: 'translateY(0)', opacity: 1 })),
			]),
			transition(':leave', [
				style({ transform: 'translateY(0)', opacity: 1 }),
				animate(
					`${ANIMATION_TIME}ms ease-out`,
					style({ transform: 'translateY(100%)', opacity: 0 }),
				),
			]),
		]),
		trigger('rotateNotesBack', [
			transition(':enter', [
				style({ transform: 'translateY(100%)', opacity: 0 }),
				animate(`${ANIMATION_TIME}ms ease-in`, style({ transform: 'translateY(0)', opacity: 1 })),
			]),
			transition(':leave', [
				style({ transform: 'translateY(0)', opacity: 1 }),
				animate(
					`${ANIMATION_TIME}ms ease-out`,
					style({ transform: 'translateY(-100%)', opacity: 0 }),
				),
			]),
		]),
	],
})
export class PinnedNotesComponent implements OnInit, OnDestroy {
	classList = input<string>('');
	notes = input<string[]>([]);
	clicked = output<void>();

	backwards = signal(false);
	noteIndex = signal(0);
	noteOne = signal('');
	noteTwo = signal('');
	subscription$!: Subscription;

	ngOnInit(): void {
		this.noteOne.set(this.notes()[0]);

		if (this.notes().length === 1) {
			return;
		}

		this.startScrolling();
	}

	ngOnDestroy(): void {
		this.subscription$?.unsubscribe();
	}

	changeNote(backwards = false) {
		this.backwards.set(backwards);
		this.scrollNote(backwards);
	}

	scrollNote(backwards = false) {
		if (this.noteOne()) {
			this.noteOne.set('');

			setTimeout(() => {
				this.noteTwo.set(this.notes()[this.noteIndex()]);
			}, ANIMATION_TIME);
		} else {
			this.noteTwo.set('');

			setTimeout(() => {
				this.noteOne.set(this.notes()[this.noteIndex()]);
			}, ANIMATION_TIME);
		}

		const notesLength = this.notes().length - 1;
		let noteIndex;

		if (backwards) {
			noteIndex = this.noteIndex() <= 0 ? notesLength : this.noteIndex() - 1;
		} else {
			noteIndex = this.noteIndex() >= notesLength ? 0 : this.noteIndex() + 1;
		}

		this.noteIndex.set(noteIndex);
	}

	startScrolling() {
		this.subscription$ = interval(5000).subscribe(() => {
			this.backwards.set(false);
			this.scrollNote();
		});
	}

	stopScrolling() {
		this.subscription$?.unsubscribe();
	}
}
