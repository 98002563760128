<div class="internet-step">
	<div class="flex text-layout-stone-gray w-1/3 max-w-52">
		<mat-icon class="material-symbols-filled">{{ icon() }}</mat-icon>
		<span class="font-bold pl-4">{{ text() }}</span>
		<ng-content select="[additional-content]"></ng-content>
	</div>
	<div class="internet-step__content" [class.grow]="contentGrow()">
		<ng-content></ng-content>
	</div>
</div>
