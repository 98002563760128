import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyGroup } from '@feature/groups/groups.types';
import { UserService } from '@feature/user/user.service';
import { CONFIG_LINKS } from '@layout/configuration-layout/configuration-layout.constants';
import { ApiService } from '@root/app/core/api/api.service';
import { AppOptions } from '@root/app/shared/apps.types';
import { startWith, tap } from 'rxjs';

import { ConfigStore } from './config.store';

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	readonly #configStore = inject(ConfigStore);
	readonly #userService = inject(UserService);
	#apiService = inject(ApiService);
	#router = inject(Router);

	getAppOptions() {
		return this.#apiService.get<AppOptions>('/android/apps/appoptions').pipe(
			startWith({
				appStatuses: {},
				appTypes: {},
				vpnOptions: {},
				vpnBlockOptions: {},
				vpnDirectOptions: {},
			} as AppOptions),
			tap((data) => {
				this.#configStore.addAppOptions(data);
			}),
		);
	}

	canAccessConfigurationRoute(route: string) {
		const allowedRoutes = CONFIG_LINKS.filter((link) =>
			link.restrictTo?.some(
				(role) => this.#userService.userSelectedGroup()?.[role as keyof CompanyGroup],
			),
		);

		if (!allowedRoutes.length) {
			this.#router.navigate(['/not-allowed']);
			return false;
		}

		const isEmypeople = this.#userService.userSelectedGroup()?.isEmypeople;
		const hasAppsAccess = this.#userService.userSelectedGroup()?.appsAccess;
		const isAdmin = this.#userService.userSelectedGroup()?.adminAccess;
		const configurationPath = route.split('/').slice(1)?.[1];

		if (isEmypeople) {
			return true;
		}

		if (isAdmin || hasAppsAccess) {
			const isRoutePermitted = allowedRoutes.some((link) => link.route === configurationPath);

			if (!isRoutePermitted) {
				this.#router.navigate([`/configuration/${allowedRoutes[0].route}`]);
				return false;
			}

			return true;
		}

		return false;
	}
}
