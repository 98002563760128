import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { CustomersStore } from '@feature/customers/customers.store';
import { TimerBannerComponent } from '@feature/device-details/timer-banner/timer-banner.component';
import { DevicesStore } from '@feature/devices/devices.store';
import { SectionMenuComponent, SectionMenuItem } from '@ui/section-menu/section-menu.component';
import { isNavigationEnd } from '@utils/routing/is-navigation-end';
import { Subscription } from 'rxjs';

import { DEVICES_LINKS, ROUTES_WITH_TIMERS } from './device-layout.constants';

@Component({
	selector: 'csd-app-device-layout',
	standalone: true,
	imports: [RouterOutlet, SectionMenuComponent, TimerBannerComponent],
	templateUrl: './device-layout.component.html',
	styleUrl: './device-layout.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceLayoutComponent implements OnInit, OnDestroy {
	readonly #customersStore = inject(CustomersStore);
	readonly #devicesStore = inject(DevicesStore);
	#activatedRoute = inject(ActivatedRoute);
	#router = inject(Router);

	deviceId = input<string>('');
	device = this.#devicesStore.selectedDevice;
	isSectionWithTimer = signal<boolean>(false);
	currentSection = signal<string>('');
	subscriptions: Subscription[] = [];

	devicesLinks = computed<SectionMenuItem[]>(() =>
		DEVICES_LINKS.map((el) => {
			const { overrideFinishedUtc } = this.device() ?? {};
			const customerCode = this.#customersStore.selectedCustomer()?.code ?? '';
			const route = `/customer/${customerCode}/devices/${this.deviceId()}/${el.route}`;

			return {
				...el,
				route,
				active: this.currentSection() === el.route,
				timer: !!(ROUTES_WITH_TIMERS.includes(el.route) && overrideFinishedUtc),
			};
		}),
	);
	shouldDisplayBanner = computed(() => {
		return this.device()?.overrideFinishedUtc && this.isSectionWithTimer();
	});

	ngOnInit(): void {
		const routeSub$ = this.#router.events
			.pipe(isNavigationEnd())
			.subscribe(this.updateSectionData.bind(this));

		this.subscriptions.push(routeSub$);
		this.updateSectionData();
		this.validateSectionWithTimer();
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	updateSectionData() {
		const urlLastSection = this.#router.url.split('/').pop();

		this.validateSectionWithTimer();
		this.currentSection.set(urlLastSection?.replace(/(\?|\#).*/gi, '') as string);
	}

	validateSectionWithTimer() {
		const { overrideFinishedUtc } = this.device();
		const currentPath = this.#activatedRoute.snapshot.firstChild?.url[0].path;
		const isTimerRoute = ROUTES_WITH_TIMERS.includes(currentPath || '');

		this.isSectionWithTimer.set(isTimerRoute && !!overrideFinishedUtc);
	}
}
