<div class="device-section__content device-actions">
	@for (button of commandButtons(); track button.command; let i = $index) {
		<csd-app-button
			classList="w-full"
			[iconClassList]="button.isLoading ? 'animate-spin' : ''"
			type="outlined"
			variation="primary"
			[icon]="button.isLoading ? 'progress_activity' : button.icon"
			[disabled]="button.isLoading"
			(click)="onCommand(i)"
		>
			{{ button.label }}
		</csd-app-button>
	}
</div>
