<section class="credentials">
	<p class="form-element">
		<mat-select class="max-w-72" hideSingleSelectionIndicator [value]="1">
			@for (church of credentialsTypes; track church.id) {
				<mat-option [value]="church.id">
					{{ church.label }}
				</mat-option>
			}
		</mat-select>
	</p>
	<p class="form-element">
		<input type="text" matInput placeholder="Name" />
	</p>
	<p class="form-element">
		<textarea matInput placeholder="Description"></textarea>
	</p>
	<p class="credentials__cta">
		<csd-app-button variation="secondary" classList="w-36">Save</csd-app-button>
	</p>
</section>
