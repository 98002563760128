import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink } from '@angular/router';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { triggerOpenClose } from '@root/app/animations';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { TagComponent } from '@ui/tag/tag.component';
import { catchError, tap } from 'rxjs';

import { CustomersStore } from '../../customers/customers.store';
import { PoliciesService } from '../../policies/policies.service';
import { PoliciesStore } from '../../policies/policies.store';
import { GroupGeneralUpdate, PolicyDetailsService } from './../policy-details.service';

@Component({
	selector: 'csd-app-general',
	standalone: true,
	imports: [
		ButtonComponent,
		LoadingIconComponent,
		MatInputModule,
		ReactiveFormsModule,
		RouterLink,
		MatIconModule,
		MatSelectModule,
		MtxPopoverModule,
		TagComponent,
	],
	templateUrl: './general.component.html',
	styleUrl: './general.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [triggerOpenClose],
})
export class GeneralComponent implements OnInit {
	readonly #customersStore = inject(CustomersStore);
	readonly #policiesStore = inject(PoliciesStore);
	#fb = inject(FormBuilder);
	#notificationService = inject(NotificationService);
	#policiesService = inject(PoliciesService);
	#policyDetailsService = inject(PolicyDetailsService);

	currentGroup = this.#policiesStore.selectedGroup;
	isLoading = signal<boolean>(false);
	form = this.#fb.group({
		name: [
			this.currentGroup()?.name || '',
			[Validators.required, Validators.pattern('[A-Za-z0-9 \'"-]*')],
		],
		churchCode: this.currentGroup()?.churchCode || '',
		ownerId: this.currentGroup()?.owner?.id || '',
	});
	loadingPolicies = this.#policiesService.isLoading;
	ownerOptions = this.#policiesStore.ownerOptions;

	get hasNameError() {
		return this.form.dirty && this.form.get('name')?.invalid;
	}
	get nameControl() {
		return this.form.get('name') as FormControl;
	}

	ngOnInit(): void {
		const allGroups = this.#policiesStore.allGroupsNames();
		const excludingCurrentGroup = allGroups.filter((group) => group !== this.currentGroup()?.name);

		if (!excludingCurrentGroup.length) {
			this.getAllPolicies();
		}
	}

	getAllPolicies() {
		const customerId = this.#customersStore.selectedCustomerId();
		let request$ = this.#policiesService.getPublicPolicies();

		if (customerId) {
			const customerCode = this.#customersStore.selectedCustomer()?.code as string;
			request$ = this.#policiesService.getByCustomer(customerCode, customerId);
		}

		request$.subscribe(this.validateName.bind(this));
	}

	onSave(event: SubmitEvent) {
		this.form.markAsDirty();
		event.preventDefault();

		const groupId = this.currentGroup()?.id;
		if (this.form.invalid || !groupId) {
			return;
		}

		const data = {
			...this.form.value,
			policyId: this.currentGroup()?.policyId,
		} as GroupGeneralUpdate;

		this.isLoading.set(true);
		this.#policyDetailsService
			.simpleUpdateGroup(groupId, data)
			.pipe(
				tap(() => this.isLoading.set(false)),
				catchError(() => {
					this.isLoading.set(false);
					throw new Error('Error on group update. Please try again.');
				}),
			)
			.subscribe(this.successfulUpdate.bind(this));
	}

	successfulUpdate() {
		this.#policiesStore.updateGroupDetails(this.currentGroup()?.id as string, {
			name: this.form.value.name as string,
			churchCode: this.form.value.churchCode as string,
			ownerId: this.form.value.ownerId as string,
			owner: this.ownerOptions().find((option) => option.id === this.form.value.ownerId),
		});
		this.#notificationService.openSuccess({
			message: `Group <strong>${this.currentGroup()?.name}</strong> was updated`,
		});
	}

	validateName() {
		const value = (this.nameControl.value ?? '').trim();
		const allGroups = this.#policiesStore.allGroupsNames();
		const nameExists = allGroups.includes(value);

		if (nameExists) {
			this.nameControl.setErrors({ nameExists: true });
			return;
		}

		this.nameControl.setErrors(null);
	}
}
