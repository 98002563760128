import { ChangeDetectionStrategy, Component, model, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
	selector: 'csd-app-name',
	standalone: true,
	imports: [MatInputModule, MatIconModule, FormsModule],
	templateUrl: './device-name.component.html',
	styleUrl: './device-name.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceNameComponent {
	text = model.required<string>();
	changed = output<string>();

	isEditing = signal<boolean>(false);
	#originalValue = signal<string>('');

	startEditMode() {
		this.isEditing.set(true);
		this.#originalValue.set(this.text());
	}

	onCancel() {
		this.isEditing.set(false);
		this.text.set(this.#originalValue());
	}

	onKey(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.onNameChange();
		}
	}

	onNameChange() {
		this.isEditing.set(false);
		this.changed.emit(this.text());
	}
}
