import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { UsersStore } from '@feature/users/users.store';

export const usersLinkResolver: ResolveFn<string> = (route, state) => {
	const userId = route.paramMap.get('userId') as string;
	const usersStore = inject(UsersStore);

	usersStore.setSelectedUser(userId);

	return userId;
};
