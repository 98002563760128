import { Routes } from '@angular/router';

import { ApkInstallComponent } from './apk-install/apk-install.component';
import { AppsComponent } from './apps/apps.component';
import { InternetComponent } from './internet/internet.component';
import { RestrictionsComponent } from './restrictions/restrictions.component';
import { StatsComponent } from './stats/stats.component';

const baseTitle = '| Devices | eMyPeople';

export default <Routes>[
	{
		path: '',
		redirectTo: 'stats',
		pathMatch: 'full',
	},
	{
		path: 'stats',
		component: StatsComponent,
		title: `Stats ${baseTitle}`,
		data: { breadcrumb: 'Stats' },
	},
	{
		path: 'internet',
		component: InternetComponent,
		title: `Internet ${baseTitle}`,
		data: { breadcrumb: 'Internet' },
	},
	{
		path: 'apps',
		component: AppsComponent,
		title: `Apps ${baseTitle}`,
		data: { breadcrumb: 'Apps' },
	},
	{
		path: 'restrictions',
		component: RestrictionsComponent,
		title: `Restrictions ${baseTitle}`,
		data: { breadcrumb: 'Restrictions' },
	},
	{
		path: 'apk-install',
		component: ApkInstallComponent,
		title: `APK Install ${baseTitle}`,
		data: { breadcrumb: 'APK Install' },
	},
];
