<div class="flex gap-2" [formGroup]="form">
	@if (device().canEditPolicy) {
		<mat-select
			class="group-dropdown"
			formControlName="group"
			[compareWith]="selectCompareValueFn"
			(selectionChange)="changed.emit('group')"
			hideSingleSelectionIndicator
		>
			@for (group of groups(); track $index + group.id) {
				<mat-option [value]="group.id">{{ group.name }}</mat-option>
			} @empty {
				<mat-option [value]="device().group?.id">{{ device().group?.name }}</mat-option>
			}
		</mat-select>
		<a
			class="content-center px-2"
			routerLink="../../../policies/{{ device().group?.id }}"
			(click)="saveCurrentLink()"
			><mat-icon class="material-symbols-filled align-bottom">edit_document</mat-icon></a
		>
	} @else {
		<div class="group-dropdown group-readonly">
			{{ device().group?.name }}
		</div>
	}
</div>
