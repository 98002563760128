<div class="device-apps">
	@if (devicePolicyApps().length) {
		<div class="search-apps">
			<input
				matInput
				class="search-apps__input"
				placeholder="Search apps"
				[(ngModel)]="searchText"
				(ngModelChange)="filterApps($event)"
			/>
			@if (searchText) {
				<button (click)="clearSearch()" class="search-apps__button">
					<mat-icon inline class="align-middle">cancel</mat-icon>
				</button>
			}
		</div>
	}

	<csd-app-loader [isLoading]="isLoading()">
		@if (launcherApps().length) {
			<csd-app-header-divider text="Launcher Apps" />
			<div class="content-apps" [class.content-apps--empty]="!launcherFilterApps().length">
				@for (item of launcherFilterApps(); track item.appId) {
					<csd-app-app-item
						class="app-item"
						[data]="item"
						[appsStatus]="appOptions().appStatuses || {}"
						[vpnOptions]="appOptions().vpnOptions || {}"
						[vpnOn]="!!device().vpnOn"
						(changedConfig)="updateStatus($event, item)"
						(reset)="resetAppConfig(item)"
					></csd-app-app-item>
				}

				@if (!launcherFilterApps().length) {
					<csd-app-empty-state [onlyText]="true">
						<ng-content empty-state-message>
							<div class="flex flex-col items-center">
								<mat-icon>rocket_launch</mat-icon>
								No launcher apps found matching your search.
							</div>
						</ng-content>
					</csd-app-empty-state>
				}
			</div>
		}

		@if (systemApps().length) {
			<csd-app-header-divider text="System Apps" />
			<div class="content-apps" [class.content-apps--empty]="!systemFilterApps().length">
				@for (item of systemFilterApps(); track item.appId) {
					<csd-app-app-item
						class="app-item"
						[data]="item"
						[appsStatus]="appOptions().appStatuses || {}"
						[vpnOptions]="appOptions().vpnOptions || {}"
						[vpnOn]="!!device().vpnOn"
						(changedConfig)="updateStatus($event, item)"
						(reset)="resetAppConfig(item)"
					></csd-app-app-item>
				}

				@if (!systemFilterApps().length) {
					<csd-app-empty-state [onlyText]="true">
						<ng-content empty-state-message>
							<div class="flex flex-col items-center">
								<mat-icon>apk_document</mat-icon>
								No system apps found matching your search.
							</div>
						</ng-content>
					</csd-app-empty-state>
				}
			</div>
		}

		@if (!devicePolicyApps().length) {
			<div class="py-10">
				<csd-app-empty-state icon="apps">
					No apps added to manage this device.
				</csd-app-empty-state>
			</div>
		}
	</csd-app-loader>
</div>
