<div class="rounded p-6 bg-layout-sky space-y-5">
    <div class="relative w-full overflow-auto">
        <input
			type="text"
			class="w-96 mt-2 mb-4 ml-2"
            placeholder="Search"
			matInput
            (keyup)="applyFilter($event)"
		/>
        <table mat-table [dataSource]="apps()">
            <ng-container matColumnDef="os">
                <th class="min-w-20 max-w-20 w-20 text-center" mat-header-cell *matHeaderCellDef>
                    OS
                </th>
                <td class="text-center align-center" mat-cell *matCellDef="let element">
                    <img
                        src="../../../assets/android-48.png"
                        class="max-w-7"
                        alt="SecureLock Android"
                    />
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>App Name</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="packageName">
                <th mat-header-cell *matHeaderCellDef>Package Name</th>
                <td mat-cell *matCellDef="let element">{{ element.packageName }}</td>
            </ng-container>
            <ng-container matColumnDef="required">
                <th mat-header-cell *matHeaderCellDef>Required</th>
                <td mat-cell *matCellDef="let element">
                    <select class="p-1 rounded-sm" [(ngModel)]="element.required" (change)="updateApp($event, element)">
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </td>
            </ng-container>
            <ng-container matColumnDef="directInternetRequired">
                <th mat-header-cell *matHeaderCellDef>VPN Bypass</th>
                <td mat-cell *matCellDef="let element">
                    <select class="p-1 rounded-sm" [(ngModel)]="element.directInternetRequired" (change)="setDirectInternetRequired($event, element)">
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    @if (element.type === 0) {                        
                        <span>Launcher</span>
                    } @else {
                        <span>System</span>
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef>Delete</th>
                <td mat-cell *matCellDef="let element">
                    <button
                            class="rounded px-4 py-2 text-layout-scarlet-red text-xl"
                            matRipple
                            (click)="deleteApp(element.id)"
                        >
						<mat-icon inline class="material-symbols-filled">delete</mat-icon>
					</button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="appTableColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: appTableColumns"
            ></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 15, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
        @if (isLoadingDetails()) {
            <div class="item-loader">
                <csd-app-loader [isLoading]="isLoadingDetails()" />
            </div>
        }
    </div>
</div>