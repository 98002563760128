import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ButtonComponent } from '@ui/button/button.component';

import { CREDENTIALS_TYPES } from './credentials.constants';

@Component({
	selector: 'csd-app-credentials',
	standalone: true,
	imports: [ButtonComponent, MatInputModule, MatSelectModule],
	templateUrl: './credentials.component.html',
	styleUrl: './credentials.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CredentialsComponent {
	credentialsTypes = CREDENTIALS_TYPES;
}
