import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	signal,
} from '@angular/core';
import { Policy } from '@feature/policy-details/policy-details.types';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';
import { AppOptions } from '@root/app/shared/apps.types';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { LoaderComponent } from '@ui/loader/loader.component';

import { ConfigStore } from '../../config/config.store';
import { DeviceDetailsService } from '../../device-details/device-details.service';
import { DevicesStore } from '../../devices/devices.store';
import { AppsListComponent } from './apps-list/apps-list.component';

@Component({
	selector: 'csd-app-apps',
	standalone: true,
	imports: [
		AppsListComponent,
		EmptyStateComponent,
		HeaderDividerComponent,
		InputSearchComponent,
		LoaderComponent,
	],
	templateUrl: './apps.component.html',
	styleUrl: './apps.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsComponent {
	#configStore = inject(ConfigStore);
	#deviceDetailsService = inject(DeviceDetailsService);
	#devicesStore = inject(DevicesStore);

	appOptions = this.#configStore.appOptions;
	device = this.#devicesStore.selectedDevice;
	isLoading = this.#deviceDetailsService.isLoading;
	launcherFilterApps = signal<AndroidAppConfig[]>([]);
	searchText = '';
	systemFilterApps = signal<AndroidAppConfig[]>([]);

	devicePolicyApps = computed(() => {
		return this.device()?.policy?.policyAppJoins || [];
	});
	launcherApps = computed(() => {
		const { appTypes = {} } = this.appOptions() as AppOptions;
		const normalAppsId = Object.values(appTypes).findIndex((el) => el === 'Launcher');

		return this.devicePolicyApps().filter((el) => el?.appResponse?.type === normalAppsId);
	});
	systemApps = computed(() => {
		const { appTypes = {} } = this.appOptions() as AppOptions;
		const systemAppsId = Object.values(appTypes).findIndex((el) => el === 'System');

		return this.devicePolicyApps().filter((el) => el?.appResponse?.type === systemAppsId);
	});

	constructor() {
		effect(this.updateComponentData.bind(this), { allowSignalWrites: true });
	}

	filterApps(value: string) {
		let launcherFilterApps = this.launcherApps();
		let systemFilterApps = this.systemApps();

		if (value) {
			launcherFilterApps = this.launcherApps().filter((item) => this.filterPackage(item, value));
			systemFilterApps = this.systemApps().filter((item) => this.filterPackage(item, value));
		}

		this.launcherFilterApps.set(launcherFilterApps);
		this.systemFilterApps.set(systemFilterApps);
	}

	filterPackage(item: AndroidAppConfig, value: string) {
		const matchRegex = new RegExp(value, 'gi');
		const { appResponse } = item;

		return appResponse?.name?.match(matchRegex) || appResponse?.packageName?.match(matchRegex);
	}

	updateComponentData() {
		if (!this.device()) {
			return;
		}

		this.filterApps(this.searchText);
	}

	updateAppInStore(appId: string, dataToUpdateLocally: Partial<AndroidAppConfig>) {
		const newAppsList = this.devicePolicyApps().map((app) => {
			return app.appId === appId ? { ...app, ...dataToUpdateLocally } : app;
		});

		this.#devicesStore.updateDeviceDetail(this.device()?.id as string, {
			policy: { ...((this.device()?.policy || {}) as Policy), policyAppJoins: newAppsList },
		});
	}
}
